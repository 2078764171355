import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import axios from "axios";
import { useState,useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import { TablePagination, Tooltip } from "@mui/material";
// import ListingAddEditForm from "./EditListingComponents";
import { baseUrl, deleteWalletData, getWalletData, updateWalletData } from "../../../service/api";
import AuthUserContext from "../../../authentication/AuthUserContext";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ListingTable() {
  // const [token,setToken]=useState("");
  const [open,setOpen]= useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const authuser=useContext(AuthUserContext);

  useEffect(()=>{
    console.log(authuser);
    if(authuser!=null){
      // navigate('/');
      // setToken()
      getRssData(authuser.jwtToken);
    }
  },[authuser]);


  useEffect(() => {
    const intervalId = setInterval(() => {
      if(authuser!=null){
        getRssData(authuser.jwtToken);
      }
    }, 5000); // 5000 ms = 5 seconds

    return () => clearInterval(intervalId);
  }, [authuser]); // empty dependency array to ensure it runs only once




  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);

  };
  const [rssData,setRssData]=useState([])

  const getRssData=(token)=>{
    

  getWalletData().then((res)=>{
    if(res?.data?.success) {    
        console.log(res.data, 'nik');
        res?.data?.data.reverse();
        setRssData(res?.data?.data)
    }
  })
  
  }


  const handleEdit = (id) => {

    updateWalletData({_id: id,status: "Completed"})
    .then((res) => {
        if (res?.data?.success) {
          alert("Record Updated successfully");
          window.location.reload(false);
        }
        console.log(res);
        if (!res?.data.success) {
          console.log("error", res);
          alert("res?.data?.message");
        }
      })
      .catch((err) => {
        if (!err?.response?.data?.success) 
        {
        alert(err?.response?.data?.message);
        }
      });

};
     
const handleDelete = (id) => {
  
    deleteWalletData({_id: id,status: "Failed"})
    .then((res) => {
        if (res?.data?.success) {
          alert("Record Updated successfully");
          window.location.reload(false);
        }
        console.log(res);
        if (!res?.data.success) {
          console.log("error", res);
          alert("res?.data?.message");
        }
      })
      .catch((err) => {
        if (!err?.response?.data?.success) 
        {
        alert(err?.response?.data?.message);
        }
      });
};

  return (
    <>
      <TableContainer sx={{marginTop:"2em"}} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell >Order Id</StyledTableCell>
              <StyledTableCell >Full Name</StyledTableCell>
              <StyledTableCell >Method</StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              
              <StyledTableCell align="left">Bank Transfer Info Available</StyledTableCell>
              <StyledTableCell align="left">CryptoCurrency Info Available</StyledTableCell>
              <StyledTableCell align="left">Amount</StyledTableCell>
              <StyledTableCell align="left">Date</StyledTableCell>
              <StyledTableCell align="left">Action</StyledTableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {rssData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  {row?._id}
                </StyledTableCell>
                
                <StyledTableCell align="left">{row?.user?.fullName}</StyledTableCell>
                
                <StyledTableCell align="left">{row?.method}</StyledTableCell>
                <StyledTableCell align="left">{row?.status}</StyledTableCell>
                
                <StyledTableCell align="left">{row?.user?.bankTransfer ? "Yes" : "No"}</StyledTableCell>
                <StyledTableCell align="left">{row?.user?.walletAddress ? "Yes" : "No"}</StyledTableCell>
                <StyledTableCell align="left">{row?.amount}</StyledTableCell>
                <StyledTableCell align="left">{(row?.date).slice(0,10)}</StyledTableCell>
                <StyledTableCell align="left">
                  <span style={{display:"flex"}}>
                  <Tooltip title="Completed">

                  <div onClick={() => handleEdit(row._id)}>
                    <DoneIcon />
                  </div>
                  </Tooltip>
                  <Tooltip title="Failed">

                  <div onClick={() => handleDelete(row._id)}>
                    <CloseIcon />
                  </div>
                  </Tooltip>
                  </span>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {/* <Grid item xs={12} md={12}> */}
                  <TablePagination
                    rowsPerPageOptions={[5, 10,15]}
                    component="div"
                    count={rssData?.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                {/* </Grid> */}
      </TableContainer>
    </>
  );
}

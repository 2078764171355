import React from 'react'
import { Box, Button, CssBaseline, IconButton, ThemeProvider, Tooltip, Typography, useTheme } from "@mui/material";
import { ColorModeContext, tokens, useMode } from "../../theme";
// import UserTable from './UserTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from "react-redux";
import { useEffect } from 'react';
// import ListingAddEditForm from './EditListingComponents';
import { useSelector } from 'react-redux';
import { getAllPublisherData, individualPublisherData } from "../redux/action";
import PublisherUserTable from './publisherusertable';
import PublisherUserEditForm from './publisheruseredit';
import { Link } from 'react-router-dom';
// import AlertDialogSlide from './RssAddPopup';

function PublisherUserComponent() {
    
  const [theme, colorMode] = useMode();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = React.useState(false);
    const {isEdit,isNew}=useSelector((state)=>state?.publisher)

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const handleAdd=()=>{
      // dispatch(renderAddRssForm({isNew:true}))
      setOpen(true);

    }
  return (
   <>

<ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
     <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          // display="flex"
          alignItems="center"
          justifyContent="center"
          // border="1px solid red"
          sx={{padding:"5em"}}
        >

          {open?<PublisherUserEditForm setEdit={setOpen} />:
          <>
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >

            <Box>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              > 
                Publishers Table
              </Typography>
            </Box>
            <button><Link to='/admin/create-publisher-user'>Add Publisher</Link></button>
          </Box>
          
          <PublisherUserTable setEdit={setOpen}/>
          </>
      
            
          } 
         {/* <ListingTable open={open}/> */}
         {/* {open?<AlertDialogSlide open={open} setOpen={setOpen} handleClose={handleClose} />:null} */}
        </Box>
        </ThemeProvider>
        </ColorModeContext.Provider>
   </>
  )
}

export default PublisherUserComponent
import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { TablePagination, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { getAllPublisherData, individualPublisherData } from "../../redux/action";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function RssTable({open}) {
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

const dispatch =useDispatch()
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [rssData,setRssData]=useState([])
  const getRssData=()=>{
  axios.get("https://koinpr.onrender.com/rss",{
    headers:{
      "token":"koinpratodayqproductrsstoken"
    }
  }).then((res)=>{
  setRssData(res?.data?.rssData)
  })
  }
  useEffect(()=>{
    getRssData()
  },[open])

  const handleEdit = (item) => {
dispatch(individualPublisherData(item))
  };

  const handleDelete = (id) => {
    // const filteredData = data.filter(
    //   (el) =>
    //     el.auditName.split(" ").join("").toLowerCase() !==
    //     auditname.split(" ").join("").toLowerCase()
    // );
    // setData(filteredData);

    axios.delete(`https://koinpr.onrender.com/rss/delete/${id}`).then((res)=>{
      if(res?.data?.success){
        alert(res?.data?.message)
    getRssData()

      }
      if(!res?.data?.success){
        alert(res?.data?.message)

      }
    })
  };
  return (
    <>
      {/* <div
        style={{
          display: "flex",
         

          borderRadius: "10px",
          marginBottom: "1em",
          marginTop: "1em",
          padding: "1%",
          width: "5%",
          marginRight: "0px",
       

          background: "black",
        }}
      >
      
        <AddIcon />
      
      </div> */}
      <TableContainer sx={{marginTop:"2em"}} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell >ID</StyledTableCell>
              <StyledTableCell align="left">Link</StyledTableCell>
              <StyledTableCell align="left">Action</StyledTableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {rssData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  {row._id}
                </StyledTableCell>
                <StyledTableCell align="left">{row.link}</StyledTableCell>
               
                <StyledTableCell align="left">
                  <span style={{display:"flex"}}>
                  <Tooltip title="Edit">

                  <div onClick={() => handleEdit(row)}>
                    <EditIcon />
                  </div>
                  </Tooltip>
                  <Tooltip title="delete">

                  <div onClick={() => handleDelete(row._id)}>
                    <DeleteIcon />
                  </div>
                  </Tooltip>
                  </span>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {/* <Grid item xs={12} md={12}> */}
                  <TablePagination
                    rowsPerPageOptions={[5, 10,15]}
                    component="div"
                    count={rssData?.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                {/* </Grid> */}
      </TableContainer>
    </>
  );
}

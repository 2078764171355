import * as React from "react";
import { styled } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import { Select } from "@mui/material";

export const CustomInputField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "grey",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "grey",
    },
    "&:hover fieldset": {
      borderColor: "grey",
    },
    "&.Mui-focused fieldset": {
      borderColor: "grey",
    },
  },
});
export const CustomSelectBox = styled(Select)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "grey",
  },
  "& .MuiSelect-select": {
    "& fieldset": {
      borderColor: "grey",
    },
    "&:hover .MuiSelect-select": {
      borderColor: "grey",
    },
    "&.Mui-focused .MuiSelect-select .MuiSelect-outlined": {
      borderColor: "grey",
    },
  },
});



import { types } from "./types"

export const getAllPublisherData=(payload)=>{
    return{
        type:types.GET_ALL_PUBLISHER_DATA,
        payload:payload
    }
}
export const individualPublisherData=(payload)=>{
    return{
        type:types.GET_INDIVIDUAL_PUBLISHER_DATA,
        payload:payload
    }
}
export const individualPublisherDataEditedSuccessfully=(payload)=>{
    return{
        type:types.INDIVIDUAL_PUBLISHER_DATA_EDITED_STATUS,
        payload:payload
    }
}
export const renderAddPublisherForm=(payload)=>{
    return{
        type:types.RENDER_ADD_PUBLISHER_FORM,
        payload:payload
    }
}
import React from 'react';
import './popup.css'

function DeletePopup(props) {
  return (
    <div className="popup">
      <div className="popup-inner">
        <p>{props.message}</p>
        <div className="button-container">
          <button onClick={props.confirm}>Confirm</button>
          <button onClick={props.cancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default DeletePopup;

import { types } from "./types";

const init={
    allPublisherData:[],
    isNew:false,
    isLoading:false,
    isEdit:false,
    individualPublisherData:{},
    totalPublisher:0
}


export const publisherReducer = (state = init, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case types.GET_ALL_PUBLISHER_DATA:
     
  
        return{
          ...state,
          allPublisherData:payload,
          
        }
      case types.GET_INDIVIDUAL_PUBLISHER_DATA:
     
  
        return{
          ...state,
          individualPublisherData:payload,
          isEdit:true
          
        }
      case types.INDIVIDUAL_PUBLISHER_DATA_EDITED_STATUS:
     
  
        return{
          ...state,
          
          isEdit:payload?.isEditData
          
        }
      case types.RENDER_ADD_PUBLISHER_FORM:
     
  
        return{
          ...state,
          
          isNew:payload?.isNew
          
        }
  
      
  
      default:
        return state;
    }
  };
  
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
  Link
} from '@mui/material'
import { tokens, ColorModeContext, useMode } from '../../theme'
// import { ColorModeContext } from "../../theme";
import { CssBaseline, ThemeProvider } from '@mui/material'

import React from 'react'
import {
  CustomInputField,
  CustomSelectBox,
} from '../../components/CustomInputField'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { individualPublisherDataEditedSuccessfully } from '../redux/action'
// import ScrollDialog from "../popup/Popup";
import { baseUrl, updateUserPublisher } from '../../service/api'

function UserAddEditForm({ setEdit }) {
  const [theme, colorMode] = useMode()

  // const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const individualPublisherData = useSelector(
    (state) => state?.publisher?.individualPublisherData
  )
  // console.log(individualPublisherData,"individualPublisherData")
  const { isEdit, isNew } = useSelector((state) => state?.publisher)
  const init = {
    firstName: '',
    lastName: '',
    countryOfResidency: '',
    address: '',
    representCompany: false,
    companyName: '',
    country: '',
    companyId: '',
    bankTransfer: false,
    cryptoCurrency: false,
    tokenType: '',
    walletAddress: '',
    fullName: '',
    email: '',
    password: '',
    emailVerified: false,
    userType: 'PUBLISHER',
    userVerified: false,
    telegram: "",
    image: null,
    loginTime: "",
    signUpTime: "",
  }

  const [input, setInput] = useState(isEdit ? individualPublisherData : init)
  console.log(input, 'input')
  console.log(isEdit, 'isedit')
  const handleChange = (e) => {
    console.log(e.target, 'terget')
    const { name, value } = e.target
    console.log(name, value)
    setInput({ ...input, [name]: value })
  }
  const handleCancel = () => {
    setEdit(false)
  }

  console.log(input, 'input')
  const handleEdit = () => {
    const {
      address,
      bankTransger,
      companyId,
      companyName,
      email,
      firstName,
      country,
      countryOfResidency,
      cryptoCurrency,
      emailVerified,
      fullName,
      lastName,
      representCompany,
      tokenType,
      userType,
      walletAddress,
      bankTransfer,
      bankName,
      swiftCode,
      accountNo,
      userVerified,
      telegram
    } = input
    const token = individualPublisherData?.jwtToken

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }
    updateUserPublisher(individualPublisherData?._id, 
      {
        address,
        bankTransger,
        companyId,
        companyName,
        email,
        firstName,
        country,
        countryOfResidency,
        cryptoCurrency,
        emailVerified,
        fullName,
        lastName,
        representCompany,
        tokenType,
        userType,
        walletAddress,
        bankTransfer,
        accountNo,
        swiftCode,
        bankName,
        userVerified,
        telegram
      }
    ) .then((res) => {
        if (res?.data?.success) {
          dispatch(
            individualPublisherDataEditedSuccessfully({ isEditData: false })
          )
          alert('Record Updated successfully')
          window.location.reload(false);
        }
        console.log(res)
        if (!res?.data.success) {
          
          console.log('error', res)
          alert('res?.data?.message')
        }
      })
      .catch((err) => {
        if (!err?.response?.data?.success) {
          alert(err?.response?.data?.message)
        }
      })
  }

  const handleAdd = () => {}
  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box
                gridColumn="span 8"
                gridRow="span 2"
                backgroundColor={colors.primary[400]}
              >
                <Box
                  mt="25px"
                  p="0 30px"
                  display="flex "
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      color={colors.grey[100]}
                      textAlign="center"
                    >
                      Users Details
                    </Typography>
                    <Typography
                      variant="h3"
                      fontWeight="bold"
                      textAlign="center"
                      color={colors.greenAccent[500]}
                    >
                      {/* $59,342.32 */}
                      {isEdit
                        ? 'Edit Advertiser Details'
                        : 'Add Advertiser Details'}
                    </Typography>
                  </Box>
                
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                label="First Name"
                                name="firstName"
                                value={input?.firstName}
                                onChange={handleChange}
                            />
                        </Grid>
            <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="lastName"
                                value={input?.lastName}
                                onChange={handleChange}
                                label="Last Name" />
                        </Grid>
            <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="countryOfResidency"
                                value={input?.countryOfResidency}
                                onChange={handleChange}
                                label="Country Of Residency" />
                        </Grid>
            <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="address"
                                value={input?.address}
                                onChange={handleChange}
                                label="Address" />
                        </Grid>
            <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="companyName"
                                value={input?.companyName}
                                onChange={handleChange}
                                label="Company Name" />
                        </Grid>
            <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="country"
                                value={input?.country}
                                onChange={handleChange}
                                label="country" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="companyId"
                                value={input?.companyId}
                                onChange={handleChange}
                                label="Company Id" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CustomInputField
                            fullWidth
                            name="fullName"
                            value={input?.fullName}
                            onChange={handleChange}
                            label="Full Name"
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CustomInputField
                            fullWidth
                            disabled
                            name="email"
                            value={input?.email}
                            onChange={handleChange}
                            label="Email"
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CustomInputField
                            fullWidth
                            disabled
                            name="password"
                            value={input?.password}
                            onChange={handleChange}
                            label="Password"
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CustomInputField
                            fullWidth
                            disabled
                            name="loginTime"
                            value={input?.loginTime.toLocaleString('en-US', { timeZone: 'Asia/Kolkata', hour12: true })}
                            onChange={handleChange}
                            label="Login time"
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CustomInputField
                            fullWidth
                            disabled
                            name="signUpTime"
                            value={input?.signUpTime.toLocaleString('en-US', { timeZone: 'Asia/Kolkata', hour12: true })}
                            onChange={handleChange}
                            label="Signup time"
                          />
                        </Grid>
                    
                        <Grid item xs={12} md={4}>
                          <CustomInputField
                            fullWidth
                            name="telegram"
                            value={input?.telegram}
                            onChange={handleChange}
                            label="telegram"
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Represent Company</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={input?.representCompany}
                                        label="Represent Company"
                                        onChange={handleChange}
                                        name="representCompany"
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </CustomSelectBox>
                                </FormControl>
                            </Box>
                        </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Email Verified
                  </InputLabel>
                  <CustomSelectBox
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={input?.emailVerified}
                    label="Email Verified"
                    name="emailVerified"
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </CustomSelectBox>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">User Verified</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={input?.userVerified}
                                        label="User Verified"
                                        name="userVerified"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No </MenuItem>
                                    </CustomSelectBox>
                                </FormControl>
                            </Box>
                        </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    User Type
                  </InputLabel>
                  <CustomSelectBox
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={input?.userType}
                    label="User Type"
                    name="userType"
                    onChange={handleChange}
                  >
                    <MenuItem value={'PUBLISHER'}>PUBLISHER</MenuItem>
                    <MenuItem value={'ADVERTISER'}>ADVERTISER </MenuItem>
                  </CustomSelectBox>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} sx={{marginTop:"20px"}}>
                <Link sx={{
                    backgroundColor: "inherit", 
                    marginTop: "2em",
                    border: "solid 0.5px white",
                    color: "inherit",
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                }}
                
                    underline="always"
                    target="_blank"
                    rel="noopener"   
                    href ={input?.image}>{input?.image ?  "View Uploaded Document" : "No Document Uploaded"}</Link>
            </Grid>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={8}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                marginTop: '2em',
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
              }}
              onClick={isEdit ? handleEdit : handleAdd}
              variant="contained"
            >
              {isEdit ? 'Update' : 'ADD'}
            </Button>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                marginTop: '2em',
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
              }}
              onClick={handleCancel}
              variant="contained"
            >
              Cancel
            </Button>
          </Grid>
        </ThemeProvider>
      </ColorModeContext.Provider>

      {/* {isEdit?<ScrollDialog/>:null} */}
    </>
  )
}

export default UserAddEditForm

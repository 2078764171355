import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { tokens, ColorModeContext, useMode } from "../../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React, { useEffect } from 'react'
import { CustomInputField, CustomSelectBox } from "../../../components/CustomInputField";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { dataEditedSuccessfully } from "../../redux/action";
import ScrollDialog from "../popup/Popup";
import { addFieldInListing, addToCart, baseUrl, updateListing, getListingApi, addListing } from "../../../service/api";

function ListingAddForm() {

    const {id}=useParams()
    const [theme, colorMode] = useMode();
    const colors = tokens(theme.palette.mode);
    const navigate=useNavigate();
    const [data,setData]= useState()
    const init = {
        websiteLink: "",
        offerTitle: "",
        listingCategory: "",
        price: 0,
        listingPrice: 0,
        offeringCategory: "",
        // topSticky: 0,
        // bottomSticky: 0,
        minPrice:0,
        fixedPrice: 0,
        websiteLanguage: "",
        // noFollowLinkAllowed:false,
        // doFollowLinkAllowed: false,
        linkType: "",
        indexedArticle: "",
        // linkedin: "",
        googleNews: "",
        socialShare: "",
        // facebook: "",
        // twitter: "",   
        // description: "",
        // visitors: "",
        verifiedByAdmin:false,
        cpm:0,
        // impressions: 0,
        other: []
    }

    const [input, setInput] = useState(init);
    const [field, setField] = useState({title: "", value: ""});
    const [cartUser,setCartUser]=useState("");
    const [image, setImage] = useState(null)

    const handleChange = (e) => {
        console.log(e.target,"terget")
        const { name, value } = e.target;

        if (name == 'file') {
            setImage(e.target.files[0])
            return;
        }
        console.log(name,value)
        setInput({ ...input, [name]: value })
        console.log(input)
    }

    const handleChangeOther = (e) => {

        const { name, value } = e.target;
        const updatedData = {
            ...input,
            other: [
              ...input.other.slice(0, parseInt(name)), // Copy the elements before the updated element
              { ...input.other[parseInt(name)], value: value }, // Updated element
              ...input.other.slice(parseInt(name)+1) // Copy the elements after the updated element
            ]
          };

          setInput(updatedData);
    }

    const handleAdd=()=>{

        console.log(field)
        addFieldInListing(data?._id, {field})
        .then((res) => {
            if (res?.data?.success) {
            alert(res?.data?.message);
            window.location.reload(false);
            }
            console.log(res);
            if (!res?.data.success) {
            console.log("error", res);
            alert("res?.data?.message");
            }
        })
        .catch((err) => {
            if (!err?.response?.data?.success) 
            {
            alert(err?.response?.data?.message);
            }
        });
    }

    const handleCartAdd=()=>{
        console.log(cartUser);
        addToCart(
            {
                userId: cartUser,
                listingId: input._id,
                name:input.offerTitle,
                price:input.listingPrice,
                image:input.image,
          }
        ).then((res) => {
              if (res?.data?.success) {
                alert(res?.data?.message);
              }
              console.log(res);
              if (!res?.data.success) {
                console.log("error", res);
                alert(res?.data?.message);
              }
            })
        .catch((err) => {
            if (!err?.response?.data?.success) 
            {
            alert(err?.response?.data?.error);
            }
        })
    }

    const addField=()=>{
        console.log("working button");
    }
    const handleCancel=()=>{
        navigate('/admin/publishers')
    }
    
    const handleEdit=()=>{

        const {
            price,
            // description,
            // visitors,
            cpm,
            // impressions,
            // topSticky,
            // bottomSticky,
            minPrice,
            fixedPrice,
            offeringCategory,
            indexedArticle,
            linkType,
            // noFollowLinkAllowed,
            websiteLink,
            // doFollowLinkAllowed,
            listingCategory,
            googleNews,
            twitter,
            offerTitle,
            websiteLanguage,
            socialShare,
            listingPrice,
            facebook,
            linkedin,
            verifiedByAdmin,
            other,
          } = input;
          const token = data?.jwtToken;

        const formData = new FormData()
        // formData.append('websiteLink', websiteLink)
        formData.append('indexedArticle', indexedArticle)
        // formData.append('doFollowLinkAllowed', doFollowLinkAllowed)
        // formData.append('noFollowLinkAllowed', noFollowLinkAllowed)
        formData.append('linkType', linkType)
        formData.append('listingCategory', listingCategory)
        formData.append('offeringCategory', offeringCategory)
        formData.append('offerTitle', offerTitle)
        // formData.append('twitter', twitter)
        // formData.append('facebook', facebook)
        formData.append('socialShare', socialShare)
        formData.append('googleNews', googleNews)
        // formData.append('linkedin', linkedin)
        // formData.append('websiteLanguage', websiteLanguage)
        formData.append('verifiedByAdmin', verifiedByAdmin)
        formData.append('listingPrice', listingPrice)
        formData.append('price', price)
        // formData.append('topSticky', topSticky)
        // formData.append('bottomSticky', bottomSticky)
        
        formData.append('minPrice', minPrice)
        formData.append('fixedPrice', fixedPrice)
        // formData.append('description', description)
        formData.append('cpm', cpm)
        // formData.append('impressions', impressions)
        // formData.append('visitors', visitors)
        formData.append('userId', id)
        console.log("other",other);
        if(image)formData.append('image', image, image.name)
          const config = {
            headers: { Authorization: `Bearer ${token}` },
          };
        addListing(formData)
        .then((res) => {
            console.log(res.data);
            if (res?.data?.success) {
            window.location.reload(false);
            alert("Record Updated successfully");
            }
            console.log(res);
            if (!res?.data.success) {
            console.log("error", res);
            alert(res?.data?.message);
            
            }
        })
        .catch((err) => {
            alert(err?.response?.data?.message);
        });
    }

    return (
        <>
        <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
        <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            // display="flex"
            alignItems="center"
            justifyContent="center"
            // border="1px solid red"
            sx={{padding:"5em"}}
            >
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Box
                                gridColumn="span 8"
                                gridRow="span 2"
                                backgroundColor={colors.primary[400]}
                            >
                                <Box
                                    mt="25px"
                                    p="0 30px"
                                    display="flex "
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Box>
                                        <Typography
                                            variant="h5"
                                            fontWeight="600"
                                            color={colors.grey[100]}
                                            textAlign="center"
                                        >
                                            Listing  Details
                                        </Typography>
                                    </Box>
                
                                </Box>

                            </Box>
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                label="Website Link"
                                name="websiteLink"
                                value={input?.websiteLink}
                                onChange={handleChange}
                            />
                        </Grid> */}
                        <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="offerTitle"
                                value={input?.offerTitle}
                                onChange={handleChange}
                                label="Offer Title" />
                        </Grid>
                        {/* <Grid item xs={12} md={12}>
                            <CustomInputField
                                fullWidth
                                name="description"
                                value={input?.description}
                                onChange={handleChange}
                                label="Description" />
                        </Grid> */}
                        {/* <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="visitors"
                                value={input?.visitors}
                                onChange={handleChange}
                                label="Visitors" />
                        </Grid> */}
                        <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="cpm"
                                value={input?.cpm}
                                type="number"
                                onChange={handleChange}
                                label="CPM" />
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="impressions"
                                value={input?.impressions}
                                type="number"
                                onChange={handleChange}
                                label="Impressions" />
                        </Grid> */}

                        {/* <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="topSticky"
                                value={input?.topSticky}
                                type="number"
                                onChange={handleChange}
                                label="topSticky" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="bottomSticky"
                                value={input?.bottomSticky}
                                type="bottomSticky"
                                onChange={handleChange}
                                label="bottomSticky" />
                        </Grid> */}

<Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="minPrice"
                                value={input?.minPrice}
                                type="number"
                                onChange={handleChange}
                                label="minPrice" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="fixedPrice"
                                value={input?.fixedPrice}
                                type="number"
                                onChange={handleChange}
                                label="fixedPrice" />
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="email"
                                value={input?.user?.email}
                                label="Email" />
                        </Grid> */}
                        <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="listingCategory"
                                value={input.listingCategory}
                                onChange={handleChange}
                                label="listingCategory" />
                        </Grid>
                        {/* <Grid item xs={12} md={4}>

                        <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Listing Category</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={input?.listingCategory}
                                        label="Listing Category"
                                        onChange={handleChange}
                                        name="listingCategory"
                                    >
                                        <MenuItem value={"pressRelease"}>Press Release</MenuItem>
                                        <MenuItem value={"sponsoredArticle"}>Sponsored Article</MenuItem>
                                        <MenuItem value={"buttonAds"}>Button Ads</MenuItem>
                                        <MenuItem value={"bannerads"}>Banner Ads</MenuItem>
                                    </CustomSelectBox>
                                </FormControl>
                            </Box>
                        </Grid> */}

                        <Grid item xs={12} md={4}>

                        <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Offering Category</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={input?.offeringCategory}
                                        label="Offering Category"
                                        onChange={handleChange}
                                        name="offeringCategory"
                                    >
                                        <MenuItem value={"contentOffering"}>Content Offering</MenuItem>
                                        <MenuItem value={"adsOffering"}>Advertisment Offering</MenuItem>
                                    </CustomSelectBox>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="price"
                                value={input?.price}
                                onChange={handleChange}
                                label="Publisher Price" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="listingPrice"
                                value={input?.listingPrice}
                                onChange={handleChange}
                                label="Listing Price" />
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="noFollowLinkAllowed"
                                value={input?.noFollowLinkAllowed}
                                onChange={handleChange}
                                label="noFollowLinkAllowed" />
                        </Grid> */}
                        {/* <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="doFollowLinkAllowed"
                                value={input?.doFollowLinkAllowed}
                                onChange={handleChange}
                                label="doFollowLinkAllowed" />
                        </Grid> */}
                        <Grid item xs={12} md={4}>
                        <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Indexed Article</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={input?.indexedArticle}
                                        label="Indexed Article"
                                        onChange={handleChange}
                                        name="indexedArticle"
                                    >
                                        <MenuItem value={"Yes"}>Yes</MenuItem>
                                        <MenuItem value={"No"}>No</MenuItem>
                                    </CustomSelectBox>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                        <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">socialShare</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={input?.socialShare}
                                        label="socialShare"
                                        onChange={handleChange}
                                        name="socialShare"
                                    >
                                        <MenuItem value={"Yes"}>Yes</MenuItem>
                                        <MenuItem value={"No"}>No</MenuItem>
                                    </CustomSelectBox>
                                </FormControl>
                            </Box>
                            
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="facebook"
                                value={input?.facebook}
                                onChange={handleChange}
                                label="Facebook" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="twitter"
                                value={input?.twitter}
                                onChange={handleChange}
                                label="Twitter" />
                        </Grid> */}
                      
                        {/* <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="websiteLanguage"
                                value={input?.websiteLanguage}
                                onChange={handleChange}
                                label="websiteLanguage" />
                        </Grid> */}
                        {/* <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="linkedin"
                                value={input?.linkedin}
                                onChange={handleChange}
                                label="linkedin" />
                        </Grid> */}
                        <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="googleNews"
                                value={input?.googleNews}
                                onChange={handleChange}
                                label="Google News" />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Link Type</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={input?.linkType}
                                        label="linkType"
                                        onChange={handleChange}
                                        name="linkType"
                                    >
                                        <MenuItem value={"noFollowLink"}>No Follow</MenuItem>
                                        <MenuItem value={"doFollowLink"}>Do Follow</MenuItem>
                                        <MenuItem value={"noLink"}>No Link</MenuItem>

                                    </CustomSelectBox>
                                </FormControl>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">DoFollowLinkAllowed</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={input?.doFollowLinkAllowed}
                                        label="doFollowLinkAllowed"
                                        onChange={handleChange}
                                        name="doFollowLinkAllowed"
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </CustomSelectBox>
                                </FormControl>
                            </Box>
                        </Grid> */}
                        <Grid item xs={12} md={4}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">verifiedByAdmin</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={input?.verifiedByAdmin}
                                        label="verifiedByAdmin"
                                        name="verifiedByAdmin"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </CustomSelectBox>
                                </FormControl>
                            </Box>
                        </Grid>
                        {(input?.other?input?.other:[]).map((data,i)=><Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name={i}
                                value={input.other[i].value}
                                onChange={handleChangeOther}
                                label={input.other[i].title} />
                        </Grid>)}

                        <Grid item xs={12} md={4}>
                        <Button sx={{
                            backgroundColor: colors.redAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}      variant="contained">
                            <Typography
                            fontWeight="600"
                            marginRight="5px"
                        >Upload Logo</Typography>
                            
                            <input
                            // hidden
                            accept="image/*"
                            type="file"
                            onChange={handleChange}
                            name="file"
                            />
                        </Button>
                        </Grid>
                     
                   

                    </Grid>
                    <Grid item xs={12} md={2}>

                    </Grid>
                    <Grid container>
                        <div>
                        <Button sx={{
                            backgroundColor: colors.blueAccent[700], marginTop: "2em",
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                        onClick={handleEdit}
                            variant="contained">
                            Add
                        </Button>
                        </div>
                        {/* <div></div> */}
                        <div style={{"margin-left": 10,"margin-bottom": 30}}>
                        <Button sx={{
                            backgroundColor: colors.blueAccent[700], marginTop: "2em",
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
onClick={handleCancel}
                            variant="contained">
                            Cancel
                        </Button>
                        </div>
                    </Grid>
                                {/* <Grid container>
                                <Box
                                    mt="25px"
                                    p="0 30px"
                                    display="flex "
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                        <Typography
                                            variant="h5"
                                            fontWeight="600"
                                            color={colors.grey[100]}
                                            textAlign="center"
                                        >
                                            Add new field
                                        </Typography>
                                    </Box>
            
                    
                    <Grid container spacing={2} sx={{marginTop: '10px'}}>
                    <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                label="Title"
                                name="title"
                                value={field.title}
                                onChange={(e)=>{setField({ ...field, "title": e.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                label="value"
                                name="value"
                                value={field.value}
                                onChange={(e)=>{setField({ ...field, "value": e.target.value })}}
                            />
                        </Grid>
                        <Grid>
                        </Grid>
                    </Grid>
                    <Button sx={{
                            backgroundColor: colors.blueAccent[700], marginTop: "2em",
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                        onClick={handleAdd}
                            variant="contained">
                            Add
                        </Button>
                        </Grid> */}

{/* 
                        <Grid container sx={{marginTop: "30px"}}>
                                <Box
                                    mt="25px"
                                    p="0 30px"
                                    display="flex "
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                        <Typography
                                            variant="h5"
                                            fontWeight="600"
                                            color={colors.grey[100]}
                                            textAlign="center"
                                        >
                                            Add listing to User's cart
                                        </Typography>
                                    </Box>
            
                    
                    <Grid container spacing={2} sx={{marginTop: '10px'}}>
                        <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                label="Enter User's ID"
                                name="value"
                                value={cartUser}
                                onChange={(e)=>{setCartUser(e.target.value)}}
                            />
                        </Grid>
                        <Grid>
                        </Grid>
                    </Grid>
                    <Button sx={{
                            backgroundColor: colors.blueAccent[700], marginTop: "2em",
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                        onClick={handleCartAdd}
                            variant="contained">
                            Add
                        </Button>
                        </Grid> */}
                </ThemeProvider>
            </ColorModeContext.Provider>
            </Box>
        </ThemeProvider>
        </ColorModeContext.Provider>
        </>
    )
}

export default ListingAddForm
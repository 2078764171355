import { Routes, Route } from 'react-router-dom'
import Login from './login/Login'
import DashboardRoutes from './Routes'
import AuditPage from './scenes/form/auditPage/AuditPage'
import AllAudit from './scenes/form/auditPage/AllAudit'
import RssComponent from './scenes/form/rss/RssComponent'
import UserComponent from './scenes/global/UserComponents'
import ListingComponent from './scenes/form/listing/ListingComponents'
import OrderComponent from './scenes/form/order/OrderComponent'
import WithdrawComponent from './scenes/form/withdrawRequest/WithdrawComponent'
import withAuthentication from './authentication/withAuthentication.js'
import UserCartComponent from './scenes/global/UserCartComponent'
import PublisherAddEditForm from './scenes/form/publisher/PublisherAddEditForm'
import Sidebar from './scenes/global/Sidebar'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ColorModeContext } from './theme'
import { useEffect, useState } from 'react'
import { useMode } from './theme'
import ListingAddEditForm from './scenes/form/listing/EditListingComponents'
import PublisherAddForm from './scenes/form/publisher/PublisherAddForm'
import ListingAddForm from './scenes/form/listing/AddListingForm'
import AuditTable from './scenes/global/AuditTable'
import PackageTable from './scenes/package/packageTable'
import PackageAddForm from './scenes/package/packageAddForm'
import PackageEditForm from './scenes/package/packageEditForm'
import Banner from './scenes/global/Banner'
import PublisherUserTable from './scenes/publisheruser/publisherusertable'
import PublisherUserComponent from './scenes/publisheruser/publisheruserComponent'
import PublisherUserAddForm from './scenes/publisheruser/publisheruseradd'

function App() {
  const [isSidebar, setIsSidebar] = useState(true)
  const [theme, colorMode] = useMode()
  useEffect(() => {
    console.log(window.location.href)
    const str = window.location.href.split('/')

    if (str[str.length - 1] == 'sign-in') {
      console.log('here')
      setIsSidebar(false)
    } else {
      setIsSidebar(true)
    }
    console.log(str[str.length - 1])
  }, [window.location.href])

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <div className="app">
          {isSidebar ? <Sidebar /> : <></>}

          <main className="content">
            <Routes>
              <Route path="/" element={<DashboardRoutes />} />
              <Route path="/admin" element={<DashboardRoutes />} />
              <Route
                path="/admin/sign-in"
                element={<Login setIsSidebar={setIsSidebar} />}
              />
              <Route path="/admin/audit" element={<AuditPage />} />
              <Route path="/admin/rss" element={<RssComponent />} />
              <Route path="/admin/users" element={<UserComponent />} />
              <Route path="/admin/banner" element={<Banner />} />
              <Route path="/admin/cart/:id" element={<UserCartComponent />} />
              <Route
                path="/admin/publisher/:id"
                element={<PublisherAddEditForm />}
              />
              <Route
                path="/admin/create-publisher-user"
                element={<PublisherUserAddForm />}
              />
              <Route path="/admin/publisher-user" element={<PublisherUserComponent />} />
              <Route
                path="/admin/create-publisher"
                element={<PublisherAddForm />}
              />
              <Route path="/admin/publishers" element={<AuditTable />} />
              <Route path="/admin/listings/:id" element={<ListingComponent />} />
              <Route path="/admin/listing/:id" element={<ListingAddEditForm />} />
              <Route path="/admin/new-listing/:id" element={<ListingAddForm />} />
              <Route path="/admin/order-history" element={<OrderComponent />} />
              <Route
                path="/admin/withdraw-request"
                element={<WithdrawComponent />}
              />
              <Route path="/admin/show-audit" element={<AllAudit />} />
              <Route path="/admin/package/:id" element={<PackageEditForm />} />
              <Route
                path="/admin/create-package"
                element={<PackageAddForm />}
              />
              <Route path="/admin/packages" element={<PackageTable />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default withAuthentication(App)

import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField';
import { updatePriorityOrder } from '../../service/api'
import { Box, CssBaseline, IconButton, ThemeProvider, Typography, useTheme } from "@mui/material";
import Paper from '@mui/material/Paper'
import { useEffect } from 'react'
import { useState, useContext } from 'react'
import { Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete'
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import DeletePopup from './popup/popup'
import EditIcon from '@mui/icons-material/Edit'
import { TablePagination, Tooltip } from '@mui/material'
import { useDispatch } from 'react-redux'
import { getAllPublisherData, individualPublisherData } from '../redux/action'
import { deleteUser, getPublishers,updatePublisherPriorityOrder,deletePublisher } from '../../service/api'
import AuthUserContext from '../../authentication/AuthUserContext'
// import { useMode } from '../../theme'
import { tokens, ColorModeContext, useMode } from "../../theme";
// import { useMode } from '../../theme'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export default function AuditTable() {

  
  const [publishersData, setPublishersData] = useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(200)
  const [popupisOpen, setPopupIsOpen] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const navigate=useNavigate();
  const [theme, colorMode] = useMode()
  const [originalData,setOriginalData]=useState([])
  const [searchInput, setSearchInput] = useState('');


  const colors = tokens(theme.palette.mode);
  const togglePopup = () => {
    setPopupIsOpen(!popupisOpen)
  }

  const openPopup = (id) => {
    setCurrentId(id)
    setPopupIsOpen(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getAllPublisher = (token) => {
    getPublishers().then((res) => {
      setPublishersData(res.data?.data)
      setOriginalData(res?.data?.data)
      console.log(res.data?.data)
    })
  }


  
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);

    const filteredList = publishersData.filter((item) =>
      item.companyName.toLowerCase().includes(inputValue.toLowerCase())
    );

    setOriginalData(filteredList)
  };



  const authuser = useContext(AuthUserContext)

  useEffect(() => {
    console.log(authuser)
    if (authuser != null) {
      getAllPublisher(authuser.jwtToken)
    }
  }, [authuser])

  const handleEdit = (item) => {
    navigate(`/admin/publisher/${item._id}`);
  }


  const handleDelete = () => {
    const id = currentId

    deletePublisher(id).then((res) => {
      if (res?.data?.success) {
        alert(res?.data?.message)
        getAllPublisher()
      }
      if (!res?.data?.success) {
        alert(res?.data?.message)
      }
    })
    setPopupIsOpen(false)
  }

  const upwardShift = (startIndex, endIndex) => {

    const copyData = []
    copyData.push(publishersData[endIndex])

    for(let i=startIndex; i<endIndex; i++){
      copyData.push(publishersData[i])
    }

    const data = []

    let index = 0

    for(let i=0; i<publishersData.length; i++){
      if(i < startIndex){
        data.push(publishersData[i])
      }
      else if(i > endIndex){
        data.push(publishersData[i])
      }
      else{
        data.push(copyData[index])
        index = index + 1
      }
    }

    for(let i=0; i<data.length; i++){
      data[i].priorityOrder = i+1
    }

    return data

  }

  const downwardShift = (startIndex, endIndex) => {

    const copyData = []

    for(let i=startIndex+1; i<=endIndex; i++){
      copyData.push(publishersData[i])
    }
    copyData.push(publishersData[startIndex])

    const data = []

    let index = 0

    for(let i=0; i<publishersData.length; i++){
      if(i < startIndex){
        data.push(publishersData[i])
      }
      else if(i > endIndex){
        data.push(publishersData[i])
      }
      else{
        data.push(copyData[index])
        index = index + 1
      }
    }

    for(let i=0; i<data.length; i++){
      data[i].priorityOrder = i+1
    }

    return data

  }

  const handleSort = (row1, row2) => {
    if(!row2.priorityOrder){
      return -1
    }
    if(!row1.priorityOrder){
      return 1;
    }
    return row1.priorityOrder - row2.priorityOrder
  }


  const handleSubmit = () => {


    const payload = [];

    for(let i=0; i<publishersData.length; i++){

      const id = publishersData[i]._id
      
      const obj ={
        'publisherId' : id,
        'priorityOrder' : publishersData[i].priorityOrder
      }
      payload.push(obj)

    }

    updatePublisherPriorityOrder(payload)
    alert("Order Updated successfully");
    window.location.reload();


  }


  const handleOrderChange = async(event, row) => {

    setSearchInput('')
    console.log(event.target.value, row)

    let startIndex =  event.target.value
    let endIndex 

    for(let i=0; i<publishersData.length; i++){
      if(publishersData[i]._id == row._id){
        endIndex = i+1
        break
      }
    }
    console.log(startIndex, endIndex);

    // if(startIndex == endIndex){
    //   return
    // }

    let data = []

    if(startIndex < endIndex){
      data = upwardShift(startIndex-1, endIndex-1)
    }

    else{
      data = downwardShift(endIndex-1, startIndex-1)
    }

    data.sort(handleSort)
    setPublishersData(data)
    setOriginalData(data)

  }

  const handleDisable = () => {
    // for(let i=0; i<publishersData.length; i++){

    //   if(publishersData[i].priorityOrder != originalData[i].priorityOrder){
    //     return false
    //   }

    // }

    return false
  }


  return (
    <>
    
<ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
     <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          alignItems="center"
          justifyContent="center"
          sx={{padding:"5em"}}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >

            <Box>
              
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                Publishers Table
              </Typography>
            </Box>
            <TextField
              label="Search"
              value={searchInput}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
            <Button 
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        marginLeft: 5
                    }}
            
                    onClick={()=>{
                      navigate(`/admin/create-publisher/`)
                    }}
                    variant="contained"
                    >
                    Add publisher 
                </Button>
          </Box>
      {popupisOpen && (
        <DeletePopup
          message="Are you sure you want to delete this?"
          confirm={handleDelete}
          cancel={togglePopup}
        />
      )}

      <TableContainer sx={{ marginTop: '2em' }} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Website</StyledTableCell>
              <StyledTableCell align="left">Company Name</StyledTableCell>
              <StyledTableCell align="left">Priority Order</StyledTableCell>
              <StyledTableCell align="left">Listings</StyledTableCell>
              <StyledTableCell align="left">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {originalData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, i) => (
                <StyledTableRow key={i}>
                  {/* <StyledTableCell component="th" scope="row">
                  {row.companyId}
                </StyledTableCell> */}
                  <StyledTableCell align="left">{row.websiteLink}</StyledTableCell>
                  <StyledTableCell align="left">{row.companyName}</StyledTableCell>
                  <StyledTableCell align="left">
                  <select 
                    type="number"
                    style={{background: 'inherit', color: 'white',backgroundColor: 'black', border: 'none', width:"100%"}}
                    onChange={(e) => handleOrderChange(e, row)}
                    value={row?.priorityOrder}
                    
                  >
                  {publishersData?.map((row, i) => (
                    <option value={(page * rowsPerPage) + i+1}>{(page * rowsPerPage) + i+1}</option>
                  ))
                  }
                  </select>
                </StyledTableCell>
                <StyledTableCell align="left">
                <Button 
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                    }}
                    onClick={()=>{
                      navigate(`/admin/listings/${row.user._id}`)
                    }}
                    variant="contained">
                    Click 
                </Button>
                </StyledTableCell>
                  <StyledTableCell align="left">
                    <span style={{ display: 'flex' }}>
                      <Tooltip title="Edit">
                        <div onClick={() => handleEdit(row)}>
                          <EditIcon />
                        </div>
                      </Tooltip>
                      <Tooltip title="delete">
                        <div onClick={() => openPopup(row._id)}>
                          <DeleteIcon />
                        </div>
                      </Tooltip>
                    </span>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 200]}
          component="div"
          count={publishersData?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Button 
                    sx={{
                        backgroundColor: colors.blueAccent[700], margin: "2em",
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                    }}
                    onClick={handleSubmit}
                    disabled={handleDisable()}
                    variant="contained">
                    Set Priority Order 
                </Button>
      </TableContainer>
      </Box>
        </ThemeProvider>
        </ColorModeContext.Provider>
    </>
  )
}

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { tokens, ColorModeContext, useMode } from '../../../theme'
import { CssBaseline, ThemeProvider } from '@mui/material'

import React from 'react'
import {
  CustomInputField,
  CustomSelectBox,
} from '../../../components/CustomInputField'
import { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createPublisher, getRegionsDataApi } from '../../../service/api'
import { useParams } from 'react-router-dom'

function PublisherAddForm() {
  const [theme, colorMode] = useMode()
  const colors = tokens(theme.palette.mode)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [individualPublisherData, setIndividualPublisherData] = useState()

  const [regions, setRegions] = useState([])

  const handleRegionChange = (_, value) => {
    setPublisherInput({ ...publisherInput, regions: value })
  }

  const init = {
    firstName: '',
    lastName: '',
    countryOfResidency: '',
    address: '',
    representCompany: false,
    companyName: '',
    country: '',
    companyId: '',
    bankTransfer: false,
    accountNo: '',
    swiftCode: '',
    bankName: '',
    cryptoCurrency: false,
    tokenType: '',
    walletAddress: '',
    fullName: '',
    email: '',
    password: '',
    emailVerified: false,
    userType: 'PUBLISHER',
    userVerified: false,
    showPublisher: false,
    pendingBalance: 0,
    currentBalance: 0,
    telegram: '',
    image: null,
  }

  const initPublisher = {
    websiteLink: '',
    description: '',
    websiteLanguage: '',
    visitors: '',
    companyName: '',
    // noFollowLinkAllowed: false,
    // doFollowLinkAllowed: false,
    linkedin: '',
    // offeringCategory: "",
    googleNews: '',
    // socialShare: "",
    facebook: '',
    twitter: '',
    regions: [],
  }

  const [input, setInput] = useState(init)
  const [publisherInput, setPublisherInput] = useState(initPublisher)

  useEffect(() => {
    getRegionsData()
  }, [])

  const getRegionsData = () => {
    getRegionsDataApi().then((res) => {
      console.log(res.data?.data)
      setRegions(res.data?.data)
    })
  }

  const handleCancel = () => {
    navigate('/admin/publishers')
  }

  const handleChange = (e) => {
    console.log(e.target.name, 'target')
    const { name, value } = e.target
    console.log(name, value)
    setInput({ ...input, [name]: value })
    console.log(input)
  }

  const handlePublisherChange = (e) => {
    console.log(e.target.name, 'target')
    const { name, value } = e.target
    if (name == 'file') {
      setImage(e.target.files[0])
      return;
    }
    console.log(name, value)
    setPublisherInput({ ...publisherInput, [name]: value })
    console.log(publisherInput)
  }

  const handleAdd = () => {
    const formData = new FormData()
    formData.append('user', JSON.stringify(input))
    formData.append('publisher', JSON.stringify(publisherInput))
    if(image)formData.append('image', image, image.name)


    createPublisher(formData)
      .then((res) => {
        if (res?.data?.success) {
          alert('Record Updated successfully')
          window.location.reload(false)
        }
        console.log(res)
        if (!res?.data.success) {
          console.log('error', res)
          alert(res?.data?.message)
        }
      })
      .catch((err) => {
        console.log(err)
        alert(err?.response?.data?.message)
      })
  }
  const [image, setImage] = useState(null)

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            // display="flex"
            alignItems="center"
            justifyContent="center"
            // border="1px solid red"
            sx={{ padding: '5em' }}
          >
            <ColorModeContext.Provider value={colorMode}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Box
                      gridColumn="span 8"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                    >
                      <Box
                        mt="25px"
                        p="0 30px"
                        display="flex "
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                            textAlign="center"
                          >
                            Publishers Details
                          </Typography>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            textAlign="center"
                            color={colors.greenAccent[500]}
                          ></Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      label="First Name"
                      name="firstName"
                      value={input?.firstName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="lastName"
                      value={input?.lastName}
                      onChange={handleChange}
                      label="Last Name"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="fullName"
                      value={input?.fullName}
                      onChange={handleChange}
                      label="Full Name"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="countryOfResidency"
                      value={input?.countryOfResidency}
                      onChange={handleChange}
                      label="Country Of Residency"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="address"
                      value={input?.address}
                      onChange={handleChange}
                      label="Address"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="companyName"
                      value={input?.companyName}
                      onChange={handleChange}
                      label="Company Name"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="country"
                      value={input?.country}
                      onChange={handleChange}
                      label="country"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="companyId"
                      value={input?.companyId}
                      onChange={handleChange}
                      label="Company Id"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="walletAddress"
                      value={input?.walletAddress}
                      onChange={handleChange}
                      label="Wallet Address"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="email"
                      value={input?.email}
                      onChange={handleChange}
                      label="Email"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="password"
                      value={input?.password}
                      onChange={handleChange}
                      label="Password"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="telegram"
                      value={input?.telegram}
                      onChange={handleChange}
                      label="Telegram"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Represent Company
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.representCompany}
                          label="Represent Company"
                          onChange={handleChange}
                          name="representCompany"
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Bank Transfer
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.bankTransfer}
                          label="Bank Transfer"
                          onChange={handleChange}
                          name="bankTransfer"
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <CustomInputField
                        fullWidth
                        name="accountNo"
                        type="string"
                        value={input?.accountNo}
                        onChange={handleChange}
                        label="Bank Account Number"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <CustomInputField
                        fullWidth
                        name="swiftCode"
                        type="string"
                        value={input?.swiftCode}
                        onChange={handleChange}
                        label="Bank Swift Code"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <CustomInputField
                        fullWidth
                        name="bankName"
                        type="string"
                        value={input?.bankName}
                        onChange={handleChange}
                        label="Bank Name"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          cryptoCurrency
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.cryptoCurrency}
                          label="cryptoCurrency"
                          name="cryptoCurrency"
                          onChange={handleChange}
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Email Verified
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.emailVerified}
                          label="Email Verified"
                          name="emailVerified"
                          onChange={handleChange}
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          User Verified
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.userVerified}
                          label="User Verified"
                          name="userVerified"
                          onChange={handleChange}
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No </MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Show Publisher
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.showPublisher}
                          label="show Publisher"
                          name="showPublisher"
                          onChange={handleChange}
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No </MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          User Type
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.userType}
                          label="User Type"
                          name="userType"
                          onChange={handleChange}
                        >
                          <MenuItem value={'PUBLISHER'}>PUBLISHER</MenuItem>
                          <MenuItem value={'ADVERTISER'}>ADVERTISER </MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="currentBalance"
                      type="number"
                      value={input?.currentBalance}
                      onChange={handleChange}
                      label="Current Balance"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="pendingBalance"
                      type="number"
                      value={input?.pendingBalance}
                      onChange={handleChange}
                      label="Pending Balance"
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={4} sx={{marginTop:"20px"}}>
                            <Link sx={{
                                backgroundColor: "inherit",
                                marginTop: "2em",
                                border: "solid 0.5px white",
                                color: "inherit",
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                            }}

                                underline="always"
                                target="_blank"
                                rel="noopener"
                                href ={input?.image}>{input?.image ?  "View Uploaded Document" : "No Document Uploaded"}</Link>
                        </Grid> */}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="companyName"
                      value={publisherInput?.companyName}
                      onChange={handlePublisherChange}
                      label="MarketPlace Company Name"
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <CustomInputField
                      fullWidth
                      name="description"
                      // type="number"
                      value={publisherInput?.description}
                      onChange={handlePublisherChange}
                      label="description"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="websiteLink"
                      // type="number"
                      value={publisherInput?.websiteLink}
                      onChange={handlePublisherChange}
                      label="websiteLink"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="visitors"
                      // type="number"
                      value={publisherInput?.visitors}
                      onChange={handlePublisherChange}
                      label="visitors"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="websiteLanguage"
                      // type="number"
                      value={publisherInput?.websiteLanguage}
                      onChange={handlePublisherChange}
                      label="websiteLanguage"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="linkedin"
                      // type="number"
                      value={publisherInput?.linkedin}
                      onChange={handlePublisherChange}
                      label="linkedin"
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="googleNews"
                                // type="number"
                                value={publisherInput?.googleNews}
                                onChange={handlePublisherChange}
                                label="googleNews" />
                        </Grid> */}
                  {/* <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="socialShare"
                                // type="number"
                                value={publisherInput?.socialShare}
                                onChange={handlePublisherChange}
                                label="socialShare" />
                        </Grid> */}
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="facebook"
                      // type="number"
                      value={publisherInput?.facebook}
                      onChange={handlePublisherChange}
                      label="facebook"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="twitter"
                      // type="number"
                      value={publisherInput?.twitter}
                      onChange={handlePublisherChange}
                      label="twitter"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                        <Button sx={{
                            backgroundColor: colors.redAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}      variant="contained">
                            <Typography
                            fontWeight="600"
                            marginRight="5px"
                        >Upload Logo</Typography>
                            
                            <input
                            // hidden
                            accept="image/*"
                            type="file"
                            onChange={handlePublisherChange}
                            name="file"
                            />
                        </Button>
                        </Grid>
                  {/* <Grid item xs={12} md={4}>
                        <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">noFollowLinkAllowed</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={publisherInput?.noFollowLinkAllowed}
                                        label="noFollowLinkAllowed"
                                        name="noFollowLinkAllowed"
                                        onChange={handlePublisherChange}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No </MenuItem>
                                    </CustomSelectBox>
                                </FormControl>
                            </Box>
                        </Grid> */}
                  {/*
                        <Grid item xs={12} md={4}>
                        <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">doFollowLinkAllowed</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={publisherInput?.doFollowLinkAllowed}
                                        label="doFollowLinkAllowed"
                                        name="doFollowLinkAllowed"
                                        onChange={handlePublisherChange}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No </MenuItem>
                                    </CustomSelectBox>
                                </FormControl>
                            </Box>
                        </Grid> */}
                  {/* <Grid item xs={12} md={4}>

                        <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Offering Category</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={publisherInput?.offeringCategory}
                                        label="Offering Category"
                                        onChange={handlePublisherChange}
                                        name="offeringCategory"
                                    >
                                        <MenuItem value={"contentOffering"}>Content Offering</MenuItem>
                                        <MenuItem value={"adsOffering"}>Advertisment Offering</MenuItem>
                                        <MenuItem value={"both"}>Both</MenuItem>

                                    </CustomSelectBox>
                                </FormControl>
                            </Box>
                        </Grid> */}
                  <Grid item xs={12} md={4}>
                    <Box>
                      <Autocomplete
                        multiple
                        options={regions}
                        getOptionLabel={(region) => region.name}
                        value={publisherInput?.regions}
                        onChange={handleRegionChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Regions"
                            variant="outlined"
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        marginTop: '2em',
                        color: colors.grey[100],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      onClick={handleAdd}
                      variant="contained"
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        marginTop: '2em',
                        color: colors.grey[100],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      onClick={handleCancel}
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </ThemeProvider>
            </ColorModeContext.Provider>
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  )
}

export default PublisherAddForm

import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { CustomInputField, CustomSelectBox } from "../../../components/CustomInputField";

import Paper from "@mui/material/Paper";
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import { useState,useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from '@mui/icons-material/Done';
import { TablePagination, Tooltip } from "@mui/material";
// import ListingAddEditForm from "./EditListingComponents";
import { baseUrl, deleteOrder, getAllOrders, updateOrder } from "../../../service/api";
import AuthUserContext from "../../../authentication/AuthUserContext";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function OrderTable() {

  const [open,setOpen]= useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(200);
  const authuser=useContext(AuthUserContext);

  useEffect(()=>{
    console.log(authuser);
    if(authuser!=null){
      // navigate('/');
      // setToken()
      getRssData(authuser.jwtToken);
    }
  },[authuser]);


  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if(authuser!=null){
  //       getRssData(authuser.jwtToken);
  //     }
  //   }, 5000); // 5000 ms = 5 seconds

  //   return () => clearInterval(intervalId);
  // }, [authuser]); // empty dependency array to ensure it runs only once


        

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);

  };
  const [rssData,setRssData]=useState([])

  const handleChange = (e,id) => {
    console.log(rssData);
    var { name, value } = e.target;
    console.log(name,value)
    if(name=="amount"){
      if(value==""){
        value=0;
      }
      else{
        value=parseInt(value);
      }
    }
    const updatedData=[
      ...rssData.slice(0,id),
      { ...rssData[id], [name]: value }, 
      ...rssData.slice(id+1)
    ];
    setRssData(updatedData);
    console.log(rssData)
}

  const getRssData=(token)=>{
    

  getAllOrders().then((res)=>{
    if(res?.data?.success) {    
        console.log(res.data);
        res?.data?.data.reverse();
        setRssData(res?.data?.data)
    }
  })
  
  }

  const handleEdit = (i,id) => {
    const amount=rssData[i]["amount"];
    const desc=rssData[i]["desc"];
    const status=rssData[i]["status"];
    console.log({amount,desc});

    updateOrder(id, {amount,desc,status})
    .then((res)=>{
      if(res?.data?.success){
        alert(res?.data?.message)
        getRssData();
      }
      if(!res?.data?.success){
        alert(res?.data?.message)
      }
    }).catch((err)=>{
      alert("Some error occurred");
    })
  };

  const handleDelete = (id) => {

    deleteOrder(id).then((res)=>{
      if(res?.data?.success){
        alert(res?.data?.message)
        getRssData()
      }
      if(!res?.data?.success){
        alert(res?.data?.message)
      }
    }).catch((err)=>{
      alert("Some error occurred");
    })
  };
  return (
    // open?<ListingAddEditForm setEdit={setOpen} data={data} />:
    <>
      <TableContainer sx={{marginTop:"2em"}} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell >Order Id</StyledTableCell>
              <StyledTableCell >Full Name</StyledTableCell>
              <StyledTableCell >Email</StyledTableCell>
              <StyledTableCell align="left">Description</StyledTableCell>
              <StyledTableCell align="left">Amount</StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left">Date</StyledTableCell>
              <StyledTableCell align="left">Action</StyledTableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {rssData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  {rssData[i]?._id}
                </StyledTableCell>
                
                <StyledTableCell align="left">{rssData[i]?.user?.fullName}</StyledTableCell>
                <StyledTableCell align="left">{rssData[i]?.user?.email}</StyledTableCell>
                <StyledTableCell align="left"><input 
                onChange={(e)=>{handleChange(e,i)}}
                name="desc"
                value={rssData[i]?.desc} /></StyledTableCell>
                <StyledTableCell align="left"><input 
                onChange={(e)=>{handleChange(e,i)}}
                name="amount"
                value={rssData[i]?.amount} /></StyledTableCell>
                <StyledTableCell align="left">


                {/* <input 
                onChange={(e)=>{handleChange(e,i)}}
                name="status"
                value={rssData[i]?.status} /> */}

                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={rssData[i]?.status}
                                        label="status"
                                        name="status"
                                        onChange={(e)=>{handleChange(e,i)}}
                                    >
                                        <MenuItem value={"pending"}>Pending</MenuItem>
                                        <MenuItem value={"complete"}>Completed</MenuItem>
                                    </CustomSelectBox>
                                </FormControl>
                                </StyledTableCell>
                <StyledTableCell align="left">{(row?.date).slice(3,15)}</StyledTableCell>
                
                
                <StyledTableCell align="left">
                  <span style={{display:"flex"}}>
                  <Tooltip title="Save">

                  <div onClick={() => handleEdit(i,row._id)}>
                    <DoneIcon sx={{cursor: "pointer"}} />
                  
                  </div>
                  </Tooltip>
                  <Tooltip title="delete">

                  <div onClick={() => handleDelete(row._id)}>
                    <DeleteIcon sx={{cursor: "pointer"}}/>
                  </div>
                  </Tooltip>
                  </span>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {/* <Grid item xs={12} md={12}> */}
                  <TablePagination
                    rowsPerPageOptions={[10,50,100,200]}
                    component="div"
                    count={rssData?.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                {/* </Grid> */}
      </TableContainer>
    </>
  );
}

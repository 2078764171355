import axios from 'axios'
import Cookies from 'universal-cookie'

const baseUrl = 'https://user.koinpr.com'
// const baseUrl = 'http://localhost:5000'
// const baseUrl = 'http://ec2-16-16-202-64.eu-north-1.compute.amazonaws.com'

const cookies = new Cookies()

const config = {
  headers: {
    Authorization: 'Bearer ' + cookies.get('auth-token'),
    Apikey: 'koinpratodayqproductrsstoken',
  },
}

export const addUser = async (data) => {
  console.log(data)
  return axios
    .post(baseUrl + `/api/user/add`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => {
      throw err
    })
}

export const signInAPI = async (data) => {
  return axios
    .post(baseUrl + '/api/admin/login', data)
    .then((res) => {
      console.log('response', res?.data?.user?.token)
      cookies.set('auth-token', res?.data?.user?.token, { path: '/' })
      return res?.data
    })
    .catch((err) => {
      console.log(err?.response?.data)
      return err?.response?.data
    })
}

export function getUserByToken() {
  return axios
    .post(baseUrl + '/api/admin/get-admin-by-token', {}, config)
    .then((res) => {
      console.log(res.data.user, 'nikhil')
      return res.data.user
    })
    .catch((err) => {
      console.log(err.message)
      return null
    })
}

export const updatePriorityOrder = async (data) => {
  console.log('data', data)

  return axios
    .post(baseUrl + '/api/listing/update/priorityOrder', data, config)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
}

export const updatePublisherPriorityOrder = async (data) => {
  console.log('data', data)

  return axios
    .post(baseUrl + '/api/publisher/update/priorityOrder', data, config)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
}

export const updatePackagePriorityOrder = async (data) => {
  console.log('data', data)

  return axios
    .post(baseUrl + '/api/package/update/priorityOrder', data, config)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
}

export const getListingCount = async () => {
  return axios
    .get(baseUrl + '/api/listing/get-count', config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const addFieldInListing = async (id, data) => {
  return axios
    .patch(baseUrl + `/api/listing/addField/${id}`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const updateListing = async (id, data) => {
  return axios
    .patch(baseUrl + `/api/listing/update/${id}`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const addListing = async (data) => {
  return axios
    .post(baseUrl + `/api/listing/add`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getAllListing = async (id) => {
  return axios
    .get(baseUrl + `/api/listing/get-all?userId=${id}`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getAllListingApi = async (id) => {
  return axios
    .get(baseUrl + `/api/listing/get-all?verifiedByAdmin=true`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getListingApi = async (id) => {
  return axios
    .get(baseUrl + `/api/listing/get-one/${id}`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getPublisherApi = async (id) => {
  return axios
    .get(baseUrl + `/api/publisher/get-one/${id}`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const deleteListing = async (id) => {
  return axios
    .delete(baseUrl + `/api/listing/delete/${id}`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getAllOrders = async () => {
  return axios
    .get(baseUrl + `/api/order/get-all`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const updateOrder = async (id, data) => {
  return axios
    .patch(baseUrl + `/api/order/update/${id}`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const deleteOrder = async (id) => {
  return axios
    .delete(baseUrl + `/api/order/delete/${id}`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getWithdrawRequests = async () => {
  return axios
    .get(baseUrl + '/api/wallet-data/getWithdrawRequests', config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getWalletData = async () => {
  return axios
    .get(baseUrl + `/api/wallet-data?status=Pending`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const updateWalletData = async (data) => {
  return axios
    .post(baseUrl + `/api/wallet-data/update`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const deleteWalletData = async (data) => {
  return axios
    .post(baseUrl + `/api/wallet-data/update`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const updateUser = async (id, data) => {
  return axios
    .patch(baseUrl + `/api/user/update/${id}`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const updateUserPublisher = async (id, data) => {
  return axios
    .patch(baseUrl + `/api/user/updateUser/${id}`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const updatePackage = async (id, data) => {
  return axios
    .patch(baseUrl + `/api/package/update/${id}`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const updateAddon = async (id, data) => {
  return axios
    .patch(baseUrl + `/api/package/update-addon/${id}`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const updateBanner = async (id, data) => {
  return axios
    .patch(baseUrl + `/api/banner/update/${id}`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const addBanner = async (id, data) => {
  return axios
    .post(baseUrl + `/api/banner/add`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const createPublisher = async (data) => {
  console.log(data)
  return axios
    .post(baseUrl + `/api/publisher/add`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => {
      throw err
    })
}

export const createPackage = async (data) => {
  console.log(data)
  return axios
    .post(baseUrl + `/api/package/add`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => {
      throw err
    })
}

export const updatePublisher = async (id, data) => {
  return axios
    .patch(baseUrl + `/api/publisher/update/${id}`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getPublishers = async () => {
  return axios
    .get(baseUrl + `/api/publisher/get-all`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getPackages = async () => {
  return axios
    .get(baseUrl + `/api/package/get-all`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getPackageRange = async () => {
  return axios
    .get(baseUrl + `/api/package/get-range`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getAdvertisers = async () => {
  return axios
    .get(baseUrl + `/api/user/get-all?userType=ADVERTISER`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getUserPublishers = async () => {
  return axios
    .get(baseUrl + `/api/user/get-all?userType=PUBLISHER`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getUserCount = async () => {
  return axios
    .get(baseUrl + '/api/user/get-count', config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getPublisherCount = async () => {
  return axios
    .get(baseUrl + '/api/user/get-count?userType=PUBLISHER', config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const deleteUser = async (id) => {
  console.log(config)
  return axios
    .delete(baseUrl + `/api/user/delete/${id}`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const deletePublisher = async (id) => {
  console.log(config)
  return axios
    .delete(baseUrl + `/api/publisher/delete/${id}`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const deletePackage = async (id) => {
  console.log(config)
  return axios
    .delete(baseUrl + `/api/package/delete/${id}`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const deleteAddon = async (id) => {
  console.log(config)
  return axios
    .delete(baseUrl + `/api/package/delete-addon/${id}`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getCartData = async (id) => {
  return axios
    .get(baseUrl + `/api/cart/get-all?userId=${id}`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getPublisherDataApi = async (id) => {
  return axios
    .get(baseUrl + `/api/publisher/get-by-userId/${id}`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getPackageDataApi = async (id) => {
  return axios
    .get(baseUrl + `/api/package/get-by-userId/${id}`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getBannerDataApi = async () => {
  return axios
    .get(baseUrl + `/api/banner/get-all`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getRegionsDataApi = async () => {
  return axios
    .get(baseUrl + `/api/region/get-all`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const deleteCartData = async (data) => {
  return axios
    .post(baseUrl + `/api/cart/delete`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const addToCart = async (data) => {
  return axios
    .post(baseUrl + `/api/cart/add`, data, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export { baseUrl }

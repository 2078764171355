
import { combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { configureStore } from "@reduxjs/toolkit";

import { publisherReducer } from "./reducer";

const reducer = combineReducers({
  // cart: cartReducer
  publisher: publisherReducer,
});

export default configureStore({
  reducer,
  composeWithDevTools,
});
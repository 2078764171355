import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Dashboard from './scenes/dashboard'
import Team from './scenes/team'
import Invoices from './scenes/invoices'
import Form from './scenes/form'
import RssComponent from './scenes/form/rss/RssComponent'
import ListingComponent from './scenes/form/listing/ListingComponents'

function DashboardRoutes() {
  // const [theme, colorMode] = useMode()
  // const [isSidebar, setIsSidebar] = useState(true)
  return (
    <>
      {/* <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Sidebar isSidebar={isSidebar} />
            <main className="content"> */}
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/team" element={<Team />} />
        <Route path="/rss" element={<RssComponent />} />
        <Route path="/listing" element={<ListingComponent />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/form" element={<Form />} />
      </Routes>
      {/* </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider> */}
    </>
  )
}

export default DashboardRoutes

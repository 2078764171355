import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import {
  deletePackage,
  getPackageRange,
  getPackages,
  updatePackagePriorityOrder,
} from '../../service/api'
import { Box, CssBaseline, ThemeProvider, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import { useEffect } from 'react'
import { useState, useContext } from 'react'
import { Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import DeletePopup from '../global/popup/popup'

import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import { TablePagination, Tooltip } from '@mui/material'
import AuthUserContext from '../../authentication/AuthUserContext'
import { tokens, ColorModeContext, useMode } from '../../theme'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export default function PackageTable() {
  const [packageData, setpackageData] = useState([])
  const [rangeData, setRangeData] = useState([])
  const [currentRange, setCurrentRange] = useState('')

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(200)
  const [popupisOpen, setPopupIsOpen] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const navigate = useNavigate()
  const [theme, colorMode] = useMode()
  const [originalData, setOriginalData] = useState([])
  const [searchInput, setSearchInput] = useState('')

  const colors = tokens(theme.palette.mode)
  const togglePopup = () => {
    setPopupIsOpen(!popupisOpen)
  }

  const openPopup = (id) => {
    console.log('here.........')
    setCurrentId(id)
    setPopupIsOpen(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getAllPackages = (token) => {
    getPackages().then((res) => {
      setpackageData(res.data?.data)
      setOriginalData(res?.data?.data)
      console.log(res.data?.data)
    })
  }

  const getAllRange = () => {
    getPackageRange().then((res) => {
      if (res.data?.data != undefined) {
        setRangeData(res.data?.data)
        if (res.data.data.length > 0) {
          setCurrentRange(res.data.data[0])
        }
      }
      console.log('rangeee', res.data?.data)
    })
  }

  // const handleInputChange = (event) => {
  //   const inputValue = event.target.value;
  //   setSearchInput(inputValue);

  //   const filteredList = packageData.filter((item) =>
  //     item.name.toLowerCase().includes(inputValue.toLowerCase())
  //   );

  //   setOriginalData(filteredList)
  // };

  // const handleRangeChange = (event) => {
  //   const inputValue = event.target.value;
  //   console.log(inputValue);
  //   setCurrentRange(inputValue)
  //   const range=inputValue.split('-')
  //   const startPrice=parseInt(range[0].trim())
  //   const endPrice=parseInt(range[1].trim())
  //   console.log(startPrice, endPrice)
  //   const filteredList = packageData.filter((item) =>
  //     item.endingPrice>=startPrice && item.endingPrice<endPrice
  //   );
  //   setOriginalData(filteredList)
  // };

  useEffect(() => {
    let filteredList = originalData.filter((item) =>
      item.name.toLowerCase().includes(searchInput.toLowerCase())
    )
    if (currentRange != '') {
      const range = currentRange.split('-')
      const startPrice = parseInt(range[0].trim())
      const endPrice = parseInt(range[1].trim())
      console.log(startPrice, endPrice)
      filteredList = filteredList.filter(
        (item) => item.endingPrice >= startPrice && item.endingPrice < endPrice
      )
    }

    // setOriginalData(filteredList)
    setpackageData(filteredList)
  }, [currentRange, searchInput])

  const authuser = useContext(AuthUserContext)

  useEffect(() => {
    console.log(authuser)
    if (authuser != null) {
      getAllPackages(authuser.jwtToken)
      getAllRange()
    }
  }, [authuser])

  const handleEdit = (item) => {
    navigate(`/admin/package/${item._id}`)
  }

  const handleDelete = () => {
    console.log('here12')
    const id = currentId

    deletePackage(id).then((res) => {
      if (res?.data?.success) {
        alert(res?.data?.message)
        window.location.reload()
      }
      if (!res?.data?.success) {
        alert(res?.data?.message)
      }
    })
    setPopupIsOpen(false)
  }

  const upwardShift = (startIndex, endIndex) => {
    const copyData = []
    copyData.push(packageData[endIndex])

    for (let i = startIndex; i < endIndex; i++) {
      copyData.push(packageData[i])
    }

    const data = []

    let index = 0

    for (let i = 0; i < packageData.length; i++) {
      if (i < startIndex) {
        data.push(packageData[i])
      } else if (i > endIndex) {
        data.push(packageData[i])
      } else {
        data.push(copyData[index])
        index = index + 1
      }
    }

    for (let i = 0; i < data.length; i++) {
      data[i].priorityOrder = i + 1
    }

    return data
  }

  const downwardShift = (startIndex, endIndex) => {
    const copyData = []

    for (let i = startIndex + 1; i <= endIndex; i++) {
      copyData.push(packageData[i])
    }
    copyData.push(packageData[startIndex])

    const data = []

    let index = 0

    for (let i = 0; i < packageData.length; i++) {
      if (i < startIndex) {
        data.push(packageData[i])
      } else if (i > endIndex) {
        data.push(packageData[i])
      } else {
        data.push(copyData[index])
        index = index + 1
      }
    }

    for (let i = 0; i < data.length; i++) {
      data[i].priorityOrder = i + 1
    }

    return data
  }

  const handleSort = (row1, row2) => {
    if (!row2.priorityOrder) {
      return -1
    }
    if (!row1.priorityOrder) {
      return 1
    }
    return row1.priorityOrder - row2.priorityOrder
  }

  const handleSubmit = () => {
    const payload = []

    for (let i = 0; i < packageData.length; i++) {
      const id = packageData[i]._id

      const obj = {
        packageId: id,
        priorityOrder: packageData[i].priorityOrder,
      }
      payload.push(obj)
    }

    updatePackagePriorityOrder(payload)
    alert('Package Updated successfully')
    window.location.reload()
  }

  const handleOrderChange = async (event, row) => {
    setSearchInput('')
    console.log(event.target.value, row)

    let startIndex = event.target.value
    let endIndex

    for (let i = 0; i < packageData.length; i++) {
      if (packageData[i]._id == row._id) {
        endIndex = i + 1
        break
      }
    }

    if (startIndex == endIndex) {
      return
    }

    let data = []

    if (startIndex < endIndex) {
      data = upwardShift(startIndex - 1, endIndex - 1)
    } else {
      data = downwardShift(endIndex - 1, startIndex - 1)
    }

    data.sort(handleSort)
    setpackageData(data)
    setOriginalData(data)
  }

  const handleDisable = () => {
    return false
  }

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            alignItems="center"
            justifyContent="center"
            sx={{ padding: '5em' }}
          >
            <Box
              mt="25px"
              p="0 30px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  color={colors.greenAccent[500]}
                >
                  Package Table
                </Typography>
              </Box>
              <TextField
                label="Search"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                variant="outlined"
                fullWidth
              />
              &nbsp;&nbsp;&nbsp;
              <Box>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color={colors.greenAccent[500]}
                >
                  Choose range:
                </Typography>
                <select
                  type="number"
                  style={{
                    background: 'inherit',
                    color: '#4cceac',
                    border: '1px solid #000',
                    backgroundColor: 'black',
                  }}
                  onChange={(e) => setCurrentRange(e.target.value)}
                  value={currentRange}
                  placeholder="Select Range"
                >
                  <option value="" disabled selected>
                    Select Range
                  </option>
                  {rangeData?.map((row, i) => (
                    <option value={row} key={i}>
                      {row}
                    </option>
                  ))}
                </select>
              </Box>
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  marginLeft: 5,
                }}
                onClick={() => {
                  navigate(`/admin/create-package/`)
                }}
                variant="contained"
              >
                Add Package
              </Button>
            </Box>

            {popupisOpen && (
              <DeletePopup
                message="Are you sure you want to delete this?"
                confirm={handleDelete}
                cancel={togglePopup}
              />
            )}

            <TableContainer sx={{ marginTop: '2em' }} component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {/* <StyledTableCell >ID</StyledTableCell> */}
                    <StyledTableCell align="left">Name</StyledTableCell>
                    <StyledTableCell align="left">Description</StyledTableCell>
                    <StyledTableCell align="left">Type</StyledTableCell>
                    {/* <StyledTableCell align="left">Starting Price</StyledTableCell> */}
                    <StyledTableCell align="left">Price</StyledTableCell>
                    <StyledTableCell align="left">
                      Priority Order
                    </StyledTableCell>
                    <StyledTableCell align="left">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {packageData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, i) => (
                      <StyledTableRow key={i}>
                        {/* <StyledTableCell component="th" scope="row">
                  {row.companyId}
                </StyledTableCell> */}
                        <StyledTableCell align="left">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.description}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.type}
                        </StyledTableCell>
                        {/* <StyledTableCell align="left">
                    {row.startingPrice}
                  </StyledTableCell> */}
                        <StyledTableCell align="left">
                          {row.endingPrice}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <select
                            type="number"
                            style={{
                              background: 'inherit',
                              color: 'white',
                              backgroundColor: 'black',
                              border: 'none',
                              width: '100%',
                            }}
                            onChange={(e) => handleOrderChange(e, row)}
                            value={row?.priorityOrder}
                          >
                            {packageData?.map((row, i) => (
                              <option value={page * rowsPerPage + i + 1}>
                                {page * rowsPerPage + i + 1}
                              </option>
                            ))}
                          </select>
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          <span style={{ display: 'flex' }}>
                            <Tooltip title="Edit">
                              <div onClick={() => handleEdit(row)}>
                                <EditIcon />
                              </div>
                            </Tooltip>
                            <Tooltip title="delete">
                              <div onClick={() => openPopup(row._id)}>
                                <DeleteIcon />
                              </div>
                            </Tooltip>
                          </span>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, 200]}
                component="div"
                count={packageData?.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  margin: '2em',
                  color: colors.grey[100],
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '10px 20px',
                }}
                onClick={handleSubmit}
                disabled={handleDisable()}
                variant="contained"
              >
                Set Priority Order
              </Button>
            </TableContainer>
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  )
}

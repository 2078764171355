import React from 'react'
import { Box, Button, CssBaseline, IconButton, ThemeProvider, Tooltip, Typography, useTheme } from "@mui/material";
import { ColorModeContext, tokens, useMode } from "../../../theme";
import WithdrawTable from './WithdrawTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
// import ListingAddEditForm from './EditListingComponents';
import { useSelector } from 'react-redux';

function OrderComponent() {
    
  const [theme, colorMode] = useMode();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = React.useState(false);
    const {isEdit,allPublisherData,isNew}=useSelector((state)=>state?.publisher)
    
  return (
   <>

<ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
     <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          // display="flex"
          alignItems="center"
          justifyContent="center"
          // border="1px solid red"
          sx={{padding:"5em"}}
        >
      <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >

            <Box>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                Withdraw Requests
              </Typography>
            </Box>
          </Box>
          <WithdrawTable />
        </Box>
        </ThemeProvider>
        </ColorModeContext.Provider>
   </>
  )
}

export default OrderComponent
import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { useEffect,useContext,useState } from "react";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { TablePagination, Tooltip } from "@mui/material";
import { useDispatch,useSelector } from "react-redux";
import { individualPublisherDataEditedSuccessfully } from "../redux/action";
import AuthUserContext from "../../authentication/AuthUserContext";
import { getAllPublisherData, individualPublisherData } from "../redux/action";
import { baseUrl, deleteCartData, getCartData } from "../../service/api";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function UserCart({id,setEdit}) {
  console.log(id);
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
const [isLoading,setIsLoading]=useState(true)
const dispatch =useDispatch()
const authuser=useContext(AuthUserContext);
const navigate=useNavigate();
const UserData=useSelector((state)=>state?.publisher?.individualPublisherData)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getAllCart=()=>{

    getCartData(id).then((res) =>{
        setIsLoading(false)
        res.data?.data.reverse();
        console.log(res.data?.data);
        setData(res.data?.data);
      });
  }

  useEffect(() => {
    
    getAllCart();
  }, []);

  const handleCart = (item) => {
    dispatch(individualPublisherData(item))
    setEdit(true);
  
  };

  const handleEdit = (item) => {
dispatch(individualPublisherData(item))
setEdit(true);
  };

  const handleDelete = (item) => {
    console.log(item);
    // return;
    deleteCartData({
        listingId: item.listingId,
        userId: item.userId
    }).then((res)=>{
      if(res?.data?.success){
        alert(res?.data?.message)
        window.location.reload();
      }
      if(!res?.data?.success){
        alert(res?.data?.message)

      }
    })
  };
  return (
    <>
      <TableContainer sx={{marginTop:"2em"}} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {/* <StyledTableCell >Company ID</StyledTableCell> */}
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Id</StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
              {/* <StyledTableCell align="left">Company Rep</StyledTableCell> */}
              {/* <StyledTableCell align="left">Company Name</StyledTableCell> */}
              {/* <StyledTableCell align="left">Price</StyledTableCell> */}
              <StyledTableCell align="left">Date</StyledTableCell>
              <StyledTableCell align="left">Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, i) => (
              <StyledTableRow key={i}>
                {/* <StyledTableCell component="th" scope="row">
                  {row.companyId}
                </StyledTableCell> */}
                <StyledTableCell align="left">{row.name}</StyledTableCell>
                <StyledTableCell align="left">
                 {row?.type=="package"?row.packageId:row.listingId}
                </StyledTableCell>
                {/* <StyledTableCell align="left">{row.representCompany?"Yes":"No"}</StyledTableCell> */}
                {/* <StyledTableCell align="left">{row.companyName}</StyledTableCell> */}
              
                {/* <StyledTableCell align="left">{row.price}</StyledTableCell> */}
                <StyledTableCell align="left">{row.type}</StyledTableCell>


                <StyledTableCell align="left">{row.date.slice(0,10)}</StyledTableCell>
                <StyledTableCell align="left">
                  <span style={{display:"flex"}}>
                  <Tooltip title="Edit">

                  <div onClick={() => handleDelete(row)}>
                    <DeleteIcon />
                  </div>
                  </Tooltip>
                  </span>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10,15]}
                    component="div"
                    count={data?.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
      </TableContainer>
    </>
  );
}
import React from 'react'
import { Box, Button, CssBaseline, IconButton, ThemeProvider, Tooltip, Typography, useTheme } from "@mui/material";
import { ColorModeContext, tokens, useMode } from "../../../theme";
import RssTable from './RssTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import AlertDialogSlide from './RssAddPopup';

function RssComponent() {
    
  const [theme, colorMode] = useMode();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const handleAdd=()=>{
      // dispatch(renderAddRssForm({isNew:true}))
      setOpen(true);

    }
  return (
   <>

<ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
     <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          // display="flex"
          alignItems="center"
          justifyContent="center"
          // border="1px solid red"
          sx={{padding:"5em"}}
        >
  <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >

            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                RSS Table
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {/* $59,342.32 */}
                
                Total
                 {/* {allPublisherData?.length} */}
                  RSS Found
              </Typography>
            </Box>
            <Box>
            <Tooltip title="Add New Publisher">
 
              <IconButton 
              onClick={handleAdd}
               >
                <AddCircleIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
</Tooltip>
            </Box>
          </Box>
         <RssTable open={open}/>
         {open?<AlertDialogSlide open={open} setOpen={setOpen} handleClose={handleClose} />:null}
        </Box>
        </ThemeProvider>
        </ColorModeContext.Provider>
   </>
  )
}

export default RssComponent
import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect,useContext } from "react";
import axios from "axios";
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { TablePagination, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import AuthUserContext from "../../authentication/AuthUserContext";
import { getAllPublisherData, individualPublisherData } from "../redux/action";
import { baseUrl, deleteUser, getAdvertisers, getAllAdvertisers, getUserPublishers } from "../../service/api";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function PublisherUserTable({setEdit}) {
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(200);
const [isLoading,setIsLoading]=useState(true)
const dispatch =useDispatch()
const authuser=useContext(AuthUserContext);
const navigate=useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getAllPublisher=(token)=>{
    getUserPublishers()
      .then((res) =>{
        setIsLoading(false)
        res.data?.data.reverse();
        setData(res.data?.data);
        dispatch(getAllPublisherData(res?.data?.data))
      });
  }

  useEffect(() => {
    if(authuser!=null){
      getAllPublisher(authuser.jwtToken);
    }
  }, [authuser]);

  //   useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if(authuser!=null){
  //       getAllPublisher(authuser.jwtToken);
  //     }
  //   }, 5000); // 5000 ms = 5 seconds

  //   return () => clearInterval(intervalId);
  // }, [authuser]); // empty dependency array to ensure it runs only once

  const handleCart = (item) => {
    dispatch(individualPublisherData(item))
    // setEdit(true);
    navigate(`/admin/cart/${item._id}`);
  };

  const handleEdit = (item) => {
dispatch(individualPublisherData(item))
setEdit(true);

  };

  const handleDelete = (id) => {

    deleteUser(id).then((res)=>{
      if(res?.data?.success){
        alert(res?.data?.message)
        window.location.reload();

    // getAllPublisher()

      }
      if(!res?.data?.success){
        alert(res?.data?.message)

      }
    })
  };
  return (
    <>
      <TableContainer sx={{marginTop:"2em"}} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {/* <StyledTableCell >Company ID</StyledTableCell> */}
              <StyledTableCell align="left">ID</StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              {/* <StyledTableCell align="left">Company Rep</StyledTableCell> */}
              {/* <StyledTableCell align="left">Company Name</StyledTableCell> */}
              <StyledTableCell align="left">User Type</StyledTableCell>
              <StyledTableCell align="left">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, i) => (
              <StyledTableRow key={i}>
                {/* <StyledTableCell component="th" scope="row">
                  {row.companyId}
                </StyledTableCell> */}
                
                <StyledTableCell align="left">{row._id}</StyledTableCell>
                <StyledTableCell align="left">{row.fullName}</StyledTableCell>
                <StyledTableCell align="left">
                 {row.email}
                </StyledTableCell>
                {/* <StyledTableCell align="left">{row.representCompany?"Yes":"No"}</StyledTableCell> */}
                {/* <StyledTableCell align="left">{row.companyName}</StyledTableCell> */}
              
                <StyledTableCell align="left">{row.userType}</StyledTableCell>


                <StyledTableCell align="left">
                  <span style={{display:"flex"}}>
                  <Tooltip title="Edit">

                  <div onClick={() => handleEdit(row)}>
                    <EditIcon />
                  </div>
                  </Tooltip>
                  <Tooltip title="delete">

                  <div onClick={() => handleDelete(row._id)}>
                    <DeleteIcon />
                  </div>
                  </Tooltip>
                  </span>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 50,100,200]}
                    component="div"
                    count={data?.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
      </TableContainer>
    </>
  );
}
import { Box, Typography, useTheme } from '@mui/material'
import { tokens } from '../../theme'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import TrafficIcon from '@mui/icons-material/Traffic'
import Header from '../../components/Header'
import StatBox from '../../components/StatBox'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import { useDispatch, useSelector } from 'react-redux'
import { renderAddPublisherForm } from '../redux/action'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'
import { baseUrl, getListingCount, getPublisherCount, getUserCount, getWithdrawRequests } from '../../service/api'
import OrderTable from '../form/order/OrderTable'
const Dashboard = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const dispatch = useDispatch()
  const { isEdit, allPublisherData, isNew } = useSelector(
    (state) => state?.publisher
  )
  console.log(isNew, 'isnew')
  const handleAdd = () => {
    dispatch(renderAddPublisherForm({ isNew: true }))
  }
  const [noOfWithdraw, setNoOfWithdraw] = useState(0)

  const [totalUsers, setTotalUsers] = useState(0)

  const [totalPublishers, setTotalPublishers] = useState(0)
  const [totalListings, setTotalListings] = useState(0)
  useEffect(() => {

    getWithdrawRequests().then((res) => {
      setNoOfWithdraw(res?.data?.count)
    })

    getUserCount().then((res) => {
      setTotalUsers(res?.data?.count)
    })

    getPublisherCount().then((res) => {
      setTotalPublishers(res?.data?.count)
    })

    getListingCount().then((res) => {
      setTotalListings(res?.data?.count)
      console.log(res)
    })
  }, [])

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="DASHBOARD"
          subtitle="Welcome Todayq to The Admin dashboard"
        />

        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box> */}
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={noOfWithdraw}
            subtitle="Withdraw Request"
            icon={
              <AccountBalanceIcon
                sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={totalUsers}
            subtitle="Total Users"
            // progress="0.50"
            // increase="+21%"
            icon={
              <PointOfSaleIcon
                sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={totalListings}
            subtitle="Total Listing"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={totalPublishers}
            subtitle="Total Publishers"
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        {/* <Box
          gridColumn="span 6"
          gridRow="span 3"
          sx={{height:"580px",padding:"2em"}}
          backgroundColor={colors.primary[400]}
        >
          <ListingTable />
        </Box> */}
        <Box
          gridColumn="span 12"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
          // mt="25px"
          p="10px 30px"
          // justifyContent="space-between"
          // alignItems="center"
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            color={colors.greenAccent[500]}
          >
            Order History
          </Typography>
          {/* <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Publishers
            </Typography>
          </Box>
          {mockTransactions.map((transaction, i) => (
            <Box
              key={`${transaction.companyName}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.companyName}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                $ {transaction.cost}
              </Box>
            </Box>
          ))} */}
          {/* <OrderH */}

          <OrderTable />
        </Box>
      </Box>
    </Box>
  )
}

export default Dashboard

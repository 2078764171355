import { useTheme } from '@emotion/react'
import {
  Box,
  Button,
  Grid,
  Paper,
  TextareaAutosize,
  TextField,
} from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { tokens } from '../../../theme'
import AllAudit from './AllAudit'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
function AuditPage() {
  const theme = useTheme()
  const colors = tokens(theme.palette)
  const navigate = useNavigate()
  const init = {
    auditName: '',
    contractAddr: '',
    auditLink: '',
    nftLink: '',
    status: '',
    desc: '',
    date: '',

    // image:""
  }
  const [auditInput, setAuditInput] = useState(init)
  const [selectedFile, setSelectedFile] = useState('')
  const [file, setFile] = useState('')
  console.log(selectedFile)
  const handleChange = (e) => {
    const { name, value } = e.target
    // if (name === "image") {
    //   setSelectedFile(e.target.files[0]);
    // }
    setAuditInput({ ...auditInput, [name]: value })
  }
  const handleFileUload = (e) => {
    // console.log(auditInput.contractAddr);
    // axios.get(`/api/image/upload`, {
    //   params: {
    //     contractAddr: auditInput.contractAddr,
    //     image: image[0]
    //   }
    // if (e.target.files.length !== 0) {
    // setFile((file) => [...file, URL.createObjectURL(e.target.files[0])]);
    setSelectedFile(e.target.files[0])
    // }
  }
  const handleAddAudit = () => {
    const { auditName, contractAddr, auditLink, nftLink, status, date, desc } =
      auditInput
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    axios
      .post(
        'https://koinpr.onrender.com/audit/addAudit',
        {
          auditName,
          contractAddr,
          auditLink,
          nftLink,
          status,
          image: selectedFile,
          desc,
          date,
        },
        config
      )
      .then((res) => {
        console.log(res)
        if (res?.status == 201) {
          alert('New Audit Added Successfully')
          navigate('/admin/show-audit')
        } else {
          alert('Something went wrong')
        }
      })
  }
  return (
    <Box
    //  className={styles.allauditCardBackground}
    >
      <Paper
        // className={styles.allauditCardBackground}
        sx={{
          background: `${colors.primary[600]} !important`,
          paddingTop: '3%',
          //   paddingBottom:"3%",
          height: '100vh',
        }}
      >
        <Paper
          sx={{
            background: `${colors.primary[800]} !important`,
            width: '50%',
            margin: 'auto',
            padding: '5%',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                onChange={handleChange}
                name="auditName"
                label="Audit Name"
                value={auditInput?.auditName}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                onChange={handleChange}
                name="contractAddr"
                label="Contract Address"
                value={auditInput?.contractAddr}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                onChange={handleChange}
                name="auditLink"
                label="Audit Link"
                value={auditInput?.auditLink}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                onChange={handleChange}
                name="nftLink"
                label="NFT Link"
                value={auditInput?.nftLink}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <TextField
                onChange={handleChange}
                name="status"
                label="Status"
                value={auditInput?.status}
              /> */}
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="status"
                    value={auditInput?.status}
                    label="Status"
                    onChange={handleChange}
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextareaAutosize
                onChange={handleChange}
                name="desc"
                label="Description"
                placeholder="Enter description"
                value={auditInput?.desc}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                onChange={handleChange}
                name="date"
                label="Date"
                type="date"
                value={auditInput?.date}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                onChange={handleFileUload}
                fullWidth
                type="file"
                //   name="image"
                //   label="Image"
              />
            </Grid>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={4}>
              <Button variant="contained" onClick={handleAddAudit}>
                Add New Audit
              </Button>
            </Grid>
            <Grid item xs={12} md={4}></Grid>
          </Grid>
        </Paper>
      </Paper>

      <AllAudit />
    </Box>
  )
}

export default AuditPage

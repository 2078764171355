import * as React from "react";
import { styled } from "@mui/material/styles";
import { tokens, ColorModeContext, useMode } from "../../../theme";
import _ from "lodash"; 
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import DeletePopup from "../../global/popup/popup";
import axios from "axios";
import { useState,useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { Button, TablePagination, Tooltip } from "@mui/material";
import ListingAddEditForm from "./EditListingComponents";
import { baseUrl, deleteListing, getAllListing } from "../../../service/api";
import AuthUserContext from "../../../authentication/AuthUserContext";
import {updatePriorityOrder} from '../../../service/api'
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ListingTable(props) {
  const [theme, colorMode] = useMode();
  const id=props.id
  console.log(id)

  // const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate=useNavigate()
  // const [token,setToken]=useState("");
  const [open,setOpen]= useState(false);
  // const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(200);
  const [rssData,setRssData]=useState([])
  const [originalRssData,setOriginalRssData]=useState([])
  const authuser=useContext(AuthUserContext);
  const [showButton, setShowButton] = React.useState({})
  const [priority, setPriority] = React.useState({});
  const [popupisOpen, setPopupIsOpen] = useState(false);
  const [currentId,setCurrentId]=useState(null);
  const togglePopup = () => {
    setPopupIsOpen(!popupisOpen);
  }

  const openPopup=(id)=>{
    setCurrentId(id);
    setPopupIsOpen(true);
  }

  useEffect(()=>{
    console.log(authuser);
    if(authuser!=null){
      getRssData(authuser.jwtToken);
    }
  },[authuser]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);

  };

  const handleSort = (row1, row2) => {
    if(!row2.priorityOrder){
      return -1
    }
    if(!row1.priorityOrder){
      return 1;
    }
    return row1.priorityOrder - row2.priorityOrder
  }
  

  const getRssData=(token)=>{
    

  getAllListing(id).then((res)=>{
    const data = res.data.data
    for(let i=0; i<data.length; i++){
      data[i].priorityOrder = i+1
    }
    data.sort(handleSort)
    console.log(res?.data?.data)
    setRssData(res?.data?.data)
    setOriginalRssData(res?.data?.data)
  })
  
  }

  const handleEdit = (item) => {
    // setData(item);
    // setOpen(true);
    navigate('/admin/listing/'+item._id);
    
  };

  const handleDelete = () => {
    const id=currentId;
    deleteListing(id).then((res)=>{
      if(res?.data?.success){
        alert(res?.data?.message)
        getRssData()
      }
      if(!res?.data?.success){
        alert(res?.data?.message)
      }
    })
    setPopupIsOpen(false);
  };

  const handleFocus = (row) => {
    const id = row._id;
    const res= {
      [id] : true
    }
    setShowButton(res);

  }

  const upwardShift = (startIndex, endIndex) => {

    const copyData = []
    copyData.push(rssData[endIndex])

    for(let i=startIndex; i<endIndex; i++){
      copyData.push(rssData[i])
    }

    const data = []

    let index = 0

    for(let i=0; i<rssData.length; i++){
      if(i < startIndex){
        data.push(rssData[i])
      }
      else if(i > endIndex){
        data.push(rssData[i])
      }
      else{
        data.push(copyData[index])
        index = index + 1
      }
    }

    for(let i=0; i<data.length; i++){
      data[i].priorityOrder = i+1
    }

    return data

  }

  const downwardShift = (startIndex, endIndex) => {

    const copyData = []

    for(let i=startIndex+1; i<=endIndex; i++){
      copyData.push(rssData[i])
    }
    copyData.push(rssData[startIndex])

    const data = []

    let index = 0

    for(let i=0; i<rssData.length; i++){
      if(i < startIndex){
        data.push(rssData[i])
      }
      else if(i > endIndex){
        data.push(rssData[i])
      }
      else{
        data.push(copyData[index])
        index = index + 1
      }
    }

    for(let i=0; i<data.length; i++){
      data[i].priorityOrder = i+1
    }

    return data

  }

  const handleOrderChange = async(event, row) => {

    console.log(event.target.value, row)

    let startIndex =  event.target.value
    let endIndex 

    for(let i=0; i<rssData.length; i++){
      if(rssData[i]._id == row._id){
        endIndex = i+1
        break
      }
    }

    if(startIndex == endIndex){
      return
    }

    let data = []

    if(startIndex < endIndex){
      data = upwardShift(startIndex-1, endIndex-1)
    }

    else{
      data = downwardShift(endIndex-1, startIndex-1)
    }

    data.sort(handleSort)
    setRssData(data)

  }

  const handleSubmit = () => {


    const payload = [];

    for(let i=0; i<rssData.length; i++){

      const id = rssData[i]._id
      
      const obj ={
        'listingId' : id,
        'priorityOrder' : rssData[i].priorityOrder
      }
      payload.push(obj)

    }

    updatePriorityOrder(payload)
    alert("Order Updated successfully");

  }

  const handleDisable = () => {
    for(let i=0; i<rssData.length; i++){

      if(rssData[i].priorityOrder != originalRssData[i].priorityOrder){
        return false
      }

    }

    return true
  }

  return (
    open?<ListingAddEditForm/>:
    <>
      {popupisOpen && <DeletePopup message="Are you sure you want to delete this?" confirm={handleDelete} cancel={togglePopup}/>}

      <TableContainer sx={{marginTop:"2em"}} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
            <StyledTableCell >Listing Id</StyledTableCell>
              <StyledTableCell >Publisher Name</StyledTableCell>
              <StyledTableCell align="left">Offer Title</StyledTableCell>
              <StyledTableCell align="left">Category</StyledTableCell>
              {/* <StyledTableCell align="left">Price</StyledTableCell> */}
              <StyledTableCell align="left">Verified By Admin</StyledTableCell>
              {/* <StyledTableCell align="left">Priority Order</StyledTableCell> */}
              <StyledTableCell align="left">Action</StyledTableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {rssData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell align="left">{row?._id}</StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row?.user?.fullName}
                </StyledTableCell>
                <StyledTableCell align="left">{row?.offerTitle}</StyledTableCell>
                <StyledTableCell align="left">{row?.listingCategory}</StyledTableCell>
                
                {/* <StyledTableCell align="left">{row?.price}</StyledTableCell> */}
                <StyledTableCell align="left">{row?.verifiedByAdmin?"Yes":"No"}</StyledTableCell>
                {/* <StyledTableCell align="left">
                  <select 
                    type="number"
                    style={{background: 'inherit', color: 'white', border: 'none', width:"100%"}}
                    onChange={(e) => handleOrderChange(e, row)}
                    value={row?.priorityOrder}
                    
                  >
                  {rssData?.map((row, i) => (
                    <option value={(page * rowsPerPage) + i+1}>{(page * rowsPerPage) + i+1}</option>
                  ))
                  }
                  </select>
                </StyledTableCell> */}
               
                <StyledTableCell align="left">
                  <span style={{display:"flex"}}>
                  <Tooltip title="Edit">

                  <div onClick={() => handleEdit(row)}>
                    <EditIcon />
                  </div>
                  </Tooltip>
                  <Tooltip title="delete">

                  <div onClick={() => openPopup(row._id)}>
                    <DeleteIcon />
                  </div>
                  </Tooltip>
                  </span>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {/* <Grid item xs={12} md={12}> */}
                  <TablePagination
                    rowsPerPageOptions={[10, 50,100,200]}
                    component="div"
                    count={rssData?.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                {/* </Grid> */}
                {/* <Button 
                    sx={{
                        backgroundColor: colors.blueAccent[700], margin: "2em",
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                    }}
                    onClick={handleSubmit}
                    disabled={handleDisable()}
                    variant="contained">
                    Set Priority Order 
                </Button> */}
      </TableContainer>
    </>
  );
}

import React from 'react'
import { withRouter } from './withRouter'
import { getUserByToken } from '../service/api'
import AuthUserContext from './AuthUserContext' //using provider's context api

// To handle App level access
const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    state = {
      authUser: null,
    }

    async componentDidMount() {
      const user = await getUserByToken()
      if (user) {
        this.setState({ authUser: user })
      } else {
        this.props.router.navigate('/admin/sign-in')
      }
    }

    render() {
      const { authUser } = this.state
      return (
        //   passing down the authUser value, so other components can consume it
        <AuthUserContext.Provider value={authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      )
    }
  }

  return withRouter(WithAuthentication)
}

export default withAuthentication

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { tokens, ColorModeContext, useMode } from '../../theme'
import { CssBaseline, ThemeProvider } from '@mui/material'

import React from 'react'

import {
  CustomInputField,
  CustomSelectBox,
} from '../../components/CustomInputField'
import { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'

import { useNavigate } from 'react-router-dom'
import { getAllListingApi, createPackage } from '../../service/api'

function PackageAddForm() {
  const [theme, colorMode] = useMode()
  const colors = tokens(theme.palette.mode)
  const navigate = useNavigate()
  const [offerings, setOfferings] = useState([])
  const [image, setImage] = useState(null)
  const [icon, setIcon] = useState(null)
  const [gradientImage, setGradientImage] = useState(null)

  const handleOfferingsChange = (_, value) => {
    setInput({ ...input, offerings: value })
  }

  const init = {
    name: '',
    description: '',
    // startingPrice: 0,
    categoryType: '',
    type: '',
    endingPrice: null,
    hotSelling: false,
    offerPrice: null,
    addons: [],
    offerings: [],
  }

  const [input, setInput] = useState(init)
  const [addOns, setAddOns] = useState([{ title: '', price: '', logo: null }])

  const handleAddAddOn = () => {
    setAddOns([...addOns, { title: '', price: '', logo: null }])
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...addOns]
    list[index][name] = value
    setAddOns(list)
  }

  const handleFileChange = (e, index) => {
    const { name, files } = e.target
    const list = [...addOns]
    list[index][name] = files[0]
    setAddOns(list)
  }

  const handleCancel = () => {
    navigate('/admin/packages')
  }

  const handleChange = (e) => {
    console.log(e.target.name, 'target')
    const { name, value } = e.target
    if (name == 'image') {
      setImage(e.target.files[0])
      return
    }
    if (name == 'icon') {
      setIcon(e.target.files[0])
      return
    }
    console.log(name, value)
    setInput({ ...input, [name]: value })
    console.log(input)
  }

  useEffect(() => {
    getListingsData()
  }, [])

  const getListingsData = () => {
    getAllListingApi().then((res) => {
      let data = res.data.data
      setOfferings(data.map((e) => e._id))
      console.log(offerings)
    })
  }

  const handleAdd = () => {
    const {
      name,
      description,
      type,
      categoryType,
      hotSelling,
      // startingPrice,
      endingPrice,
      offerings,
      offerPrice,
    } = input

    console.log(addOns)
    const formData = new FormData()
    formData.append('name', name)
    formData.append('description', description)
    formData.append('categoryType', categoryType)
    formData.append('type', type)
    formData.append('hotSelling', hotSelling)

    addOns.forEach((addon, index) => {
      const addonIndex = index + 1
      formData.append(`addon${addonIndex}title`, addon.title)
      formData.append(`addon${addonIndex}price`, addon.price)
      if (addon.logo)
        formData.append(`addon${addonIndex}logo`, addon.logo, addon.logo.name)
    })

    // formData.append('startingPrice', startingPrice)
    formData.append('endingPrice', endingPrice)
    formData.append('offerPrice', offerPrice)
    formData.append('offerings', JSON.stringify(offerings))

    if (image) formData.append('image', image, image.name)
    if (icon) formData.append('icon', icon, icon.name)

    createPackage(formData)
      .then((res) => {
        console.log(res.data)
        if (res?.data?.success) {
          window.location.reload(false)
          alert('Package Created successfully')
        }
        console.log(res)
        if (!res?.data.success) {
          console.log('error', res)
          alert('res?.data?.message')
        }
      })
      .catch((err) => {
        if (!err?.response?.data?.success) {
          alert(err?.response?.data?.message)
        }
      })
  }

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            // display="flex"
            alignItems="center"
            justifyContent="center"
            // border="1px solid red"
            sx={{ padding: '5em' }}
          >
            <ColorModeContext.Provider value={colorMode}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Box
                      gridColumn="span 8"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                    >
                      <Box
                        mt="25px"
                        p="0 30px"
                        display="flex "
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                            textAlign="center"
                          >
                            Package Details
                          </Typography>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            textAlign="center"
                            color={colors.greenAccent[500]}
                          ></Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      label="Name"
                      name="name"
                      value={input?.name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="description"
                      value={input?.description}
                      onChange={handleChange}
                      label="Description"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Category Type
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.categoryType}
                          label="categoryType"
                          name="categoryType"
                          onChange={handleChange}
                        >
                          <MenuItem value={'Press Release'}>
                            Press Release
                          </MenuItem>
                          <MenuItem value={'Sponsored'}>Sponsored</MenuItem>
                          <MenuItem value={'Organic'}>Organic</MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Type
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.type}
                          label="type"
                          name="type"
                          onChange={handleChange}
                        >
                          <MenuItem value={'coloured'}>Coloured</MenuItem>
                          <MenuItem value={'non-coloured'}>
                            Non Coloured
                          </MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>

                  {/* <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="startingPrice"
                                type="number"
                                value={input?.startingPrice}
                                onChange={handleChange}
                                label="Starting Price" />
                        </Grid> */}

                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="endingPrice"
                      type="number"
                      value={input?.endingPrice}
                      onChange={handleChange}
                      label="Original Price"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="offerPrice"
                      type="number"
                      value={input?.offerPrice}
                      onChange={handleChange}
                      label="Offer Price"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          HotSelling
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.hotSelling}
                          label="hotSelling"
                          name="hotSelling"
                          onChange={handleChange}
                        >
                          <MenuItem value={'true'}>Yes</MenuItem>
                          <MenuItem value={'false'}>No</MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Button
                      sx={{
                        backgroundColor: colors.redAccent[700],
                        color: colors.grey[100],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      variant="contained"
                    >
                      <Typography fontWeight="600" marginRight="5px">
                        Upload Image
                      </Typography>

                      <input
                        // hidden
                        accept="image/*"
                        type="file"
                        onChange={handleChange}
                        name="image"
                      />
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Button
                      sx={{
                        backgroundColor: colors.redAccent[700],
                        color: colors.grey[100],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      variant="contained"
                    >
                      <Typography fontWeight="600" marginRight="5px">
                        Upload Logo
                      </Typography>

                      <input
                        // hidden
                        accept="image/*"
                        type="file"
                        onChange={handleChange}
                        name="icon"
                      />
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box>
                      <Autocomplete
                        multiple
                        options={offerings}
                        getOptionLabel={(offering) => offering}
                        value={input?.offerings}
                        onChange={handleOfferingsChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Offerings"
                            variant="outlined"
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <br></br>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Box
                      gridColumn="span 8"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                    >
                      <Box
                        mt="25px"
                        p="0 30px"
                        display="flex "
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                            textAlign="center"
                          >
                            Add Ons
                          </Typography>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            textAlign="center"
                            color={colors.greenAccent[500]}
                          ></Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  {addOns.map((addOn, i) => (
                    <Grid container spacing={2} key={i}>
                      <Grid item xs={12} md={4}>
                        <CustomInputField
                          fullWidth
                          label="Title"
                          name="title"
                          value={addOn.title}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomInputField
                          fullWidth
                          label="Price"
                          name="price"
                          value={addOn.price}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Button
                          sx={{
                            backgroundColor: colors.redAccent[700],
                            color: colors.grey[100],
                            fontSize: '14px',
                            fontWeight: 'bold',
                            padding: '10px 20px',
                          }}
                          variant="contained"
                        >
                          <Typography fontWeight="600" marginRight="5px">
                            Upload Logo
                          </Typography>
                          <input
                            // hidden
                            accept="image/*"
                            type="file"
                            onChange={(e) => handleFileChange(e, i)}
                            name="logo"
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
                  <Button variant="contained" onClick={handleAddAddOn}>
                    Add More +
                  </Button>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        marginTop: '2em',
                        color: colors.grey[100],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      onClick={handleAdd}
                      variant="contained"
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        marginTop: '2em',
                        color: colors.grey[100],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      onClick={handleCancel}
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </ThemeProvider>
            </ColorModeContext.Provider>
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  )
}

export default PackageAddForm

import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, useTheme, Link } from "@mui/material";
import { tokens, ColorModeContext, useMode } from "../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

import React from 'react'
import { CustomInputField, CustomSelectBox } from "../../components/CustomInputField";
import { useEffect,useContext,useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl, updateUser,getPublisherDataApi,updatePublisher, getRegionsDataApi, getAllListing, getPackageDataApi, updatePackage, getBannerDataApi, addBanner, updateBanner } from "../../service/api";
import { useParams } from 'react-router-dom'

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import AuthUserContext from '../../authentication/AuthUserContext'





function Banner() {

    const [image, setImage] = useState(null)
    const [mobileImage, setMobileImage] = useState(null)

    const handleOfferingsChange = (_, value) => {
        setInput({ ...input, offerings: value })
    };
  
    const [theme, colorMode] = useMode();
    const colors = tokens(theme.palette.mode);
    const navigate=useNavigate();
    const dispatch=useDispatch()
    const [id, setId] = useState(-1);

    const init = {
        active: false,
        link: "",
        image: null,
        mobileImage: null,
    }

    const [input, setInput] = useState(init);
    const authuser = useContext(AuthUserContext)



    useEffect(() => {
        if(authuser){
            getBannerData();
        }
      }, [authuser]);

      const getBannerData=()=>{
        
        getBannerDataApi(id).then((res) =>{
            console.log(res.data.data);

            if(res.data.data.length>0){  
                const data=res.data.data[0]         
                console.log(data);
                setInput(data)
                setId(data._id)
            }
          });
          
      }

    
    const handleCancel= ()=>{
        navigate('/admin')
    }


    const handleChange = (e) => {
        console.log(e.target.name,"target")
        const { name, value } = e.target;
        if (name == 'image') {
            setImage(e.target.files[0])
            return;
        }
        if (name == 'mobileImage') {
            setMobileImage(e.target.files[0])
            return;
        }
        console.log(name,value)
        setInput({ ...input, [name]: value })
        console.log(input)
    }


    

    const handleEdit=()=>{

        const {
            link,
            active
          } = input;

        const formData = new FormData()
        formData.append('link', link)
        formData.append('active', active)
        if(image)formData.append('image', image, image.name)
        if(mobileImage)formData.append('mobileImage', mobileImage, mobileImage.name)
        if(id!=-1){
            updateBanner(id,formData)
        .then((res) => {
            console.log(res.data);
            if (res?.data?.success) {
            window.location.reload(false);
            alert("Banner Edited successfully");
            }
            console.log(res);
            if (!res?.data.success) {
            console.log("error", res);
            alert("res?.data?.message");
            
            }
        })
        .catch((err) => {
            if (!err?.response?.data?.success) 
            {
            alert(err?.response?.data?.message);
            }
        });
        }
        else{
            addBanner(formData)
            .then((res) => {
                console.log(res.data);
                if (res?.data?.success) {
                window.location.reload(false);
                alert("Banner Edited successfully");
                }
                console.log(res);
                if (!res?.data.success) {
                console.log("error", res);
                alert("res?.data?.message");
                
                }
            })
            .catch((err) => {
                if (!err?.response?.data?.success) 
                {
                alert(err?.response?.data?.message);
                }
            });
        }
}


    return (
        <>
        <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
     <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          // display="flex"
          alignItems="center"
          justifyContent="center"
          // border="1px solid red"
          sx={{padding:"5em"}}
        >

            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Box
                                gridColumn="span 8"
                                gridRow="span 2"
                                backgroundColor={colors.primary[400]}


                            >
                                <Box
                                    mt="25px"
                                    p="0 30px"
                                    display="flex "
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Box>
                                        <Typography
                                            variant="h5"
                                            fontWeight="600"
                                            color={colors.grey[100]}
                                            textAlign="center"
                                        >
                                            Package Details
                                        </Typography>
                                        <Typography
                                            variant="h3"
                                            fontWeight="bold"
                                            textAlign="center"

                                            color={colors.greenAccent[500]}
                                        >
                                        </Typography>
                                    </Box>
                                </Box>

                            </Box>
                        </Grid>
                    
                        <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="link"
                                value={input?.link}
                                onChange={handleChange}
                                label="Link" />
                        </Grid>
        
                        <Grid item xs={12} md={4}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Active</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={input?.active}
                                        label="Active"
                                        name="active"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </CustomSelectBox>
                                </FormControl>
                            </Box>
                        </Grid>
                    
                        <Grid item xs={12} md={4}>
                        <Button sx={{
                            backgroundColor: colors.redAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}      variant="contained">
                            <Typography
                            fontWeight="600"
                            marginRight="5px"
                        >Upload Desktop Banner</Typography>
                            
                            <input
                            // hidden
                            accept="image/*"
                            type="file"
                            onChange={handleChange}
                            name="image"
                            />
                        </Button>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{marginTop:"20px"}}>
                            <Link sx={{
                                backgroundColor: "inherit", 
                                marginTop: "2em",
                                border: "solid 0.5px white",
                                color: "inherit",
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                            }}
                            
                                underline="always"
                                target="_blank"
                                rel="noopener"   
                                href ={input?.image}>{input?.image ?  "View Desktop Banner" : "No Desktop Banner"}</Link>
                        </Grid>

                        <Grid item xs={12} md={4}>
                        <Button sx={{
                            backgroundColor: colors.redAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}      variant="contained">
                            <Typography
                            fontWeight="600"
                            marginRight="5px"
                        >Upload Mobile Banner</Typography>
                            
                            <input
                            // hidden
                            accept="image/*"
                            type="file"
                            onChange={handleChange}
                            name="mobileImage"
                            />
                        </Button>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{marginTop:"20px"}}>
                            <Link sx={{
                                backgroundColor: "inherit", 
                                marginTop: "2em",
                                border: "solid 0.5px white",
                                color: "inherit",
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                            }}
                            
                                underline="always"
                                target="_blank"
                                rel="noopener"   
                                href ={input?.mobileImage}>{input?.mobileImage ?  "View Mobile banner" : "No Mobile banner Uploaded"}</Link>
                        </Grid>

                

                    </Grid>
                    <Grid container spacing={1}>
                    <Grid item >
                        <Button sx={{
                            backgroundColor: colors.blueAccent[700], marginTop: "2em",
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",    
                        }}
                        onClick={handleEdit}
                            variant="contained">
                            Update
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button sx={{
                            backgroundColor: colors.blueAccent[700], marginTop: "2em",
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                        onClick={handleCancel}
                            variant="contained">
                            Cancel
                        </Button>
                    </Grid>
                    </Grid>
                
                </ThemeProvider>
            </ColorModeContext.Provider>
            
        </Box>
        </ThemeProvider>
        </ColorModeContext.Provider>

        </>
    )
}

export default Banner
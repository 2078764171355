import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { CustomInputField } from '../../../components/CustomInputField'
import { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function AlertDialogSlide({ open, setOpen, handleClose }) {
  //   const [open, setOpen] = React.useState(false);
  const init = {
    link: '',
  }
  const [input, setInput] = useState(init)
  const [rssData, setRssData] = useState([])
  const navigate = useNavigate()
  const handleClickOpen = () => {
    setOpen(true)
  }

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  const handleChange = (e) => {
    const { name, value } = e.target
    setInput({ ...input, [name]: value })
  }
  const handleAdd = () => {
    const { link } = input

    const getRssData = () => {
      axios.get('https://koinpr.onrender.com/rss/').then((res) => {
        setRssData(res?.data?.rssData)
      })
    }

    axios
      .post('https://koinpr.onrender.com/rss/addRss', {
        link,
      })
      .then((res) => {
        if (res.data?.success) {
          alert('Rss added successfully')
        }
        if (!res?.data?.success) {
          alert(res?.data?.message)
        }
        handleClose()
        navigate('/admin/rss')
      })
  }

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Add RSS'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <CustomInputField
              sx={{ marginTop: '2em' }}
              name="link"
              label="Rss Link"
              onChange={handleChange}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ color: 'white' }}
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ color: 'white' }}
            variant="contained"
            onClick={handleAdd}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Link,
} from '@mui/material'
import { tokens, ColorModeContext, useMode } from '../../theme'
import { CssBaseline, ThemeProvider } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Tooltip } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import React from 'react'
import {
  CustomInputField,
  CustomSelectBox,
} from '../../components/CustomInputField'
import { useEffect, useState } from 'react'
import DeletePopup from '../global/popup/popup'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  getPackageDataApi,
  updatePackage,
  getAllListingApi,
  updateAddon
} from '../../service/api'
import { useParams } from 'react-router-dom'

import Autocomplete from '@mui/material/Autocomplete'

function PackageEditForm() {
  const [offerings, setOfferings] = useState([])
  const [image, setImage] = useState(null)
  const [icon, setIcon] = useState(null)

  const [logoAddon, setlogoAddon] = useState(null)


  const handleOfferingsChange = (_, value) => {
    setInput({ ...input, offerings: value })
  }
  const [editing, setEditing] = useState(null)

  const [theme, colorMode] = useMode()
  const colors = tokens(theme.palette.mode)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [individualPackageData, setindividualPackageData] = useState()

  const init = {
    name: '',
    description: '',
    // startingPrice: 0,
    categoryType: '',
    type: '',
    endingPrice: 0,
    hotSelling: false,
    offerPrice: 0,
    addons: [],
    offerings: [],
  }

  const [addOns, setAddOns] = useState([{ title: '', price: '', logo: null }])
  const handleAddAddOn = () => {
    setAddOns([...addOns, { title: '', price: '', logo: null }])
  }
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...addOns]
    list[index][name] = value
    setAddOns(list)
  }
  const handleFileChange = (e, index) => {
    const { name, files } = e.target
    const list = [...addOns]
    list[index][name] = files[0]
    setAddOns(list)
  }

  const togglePopup = () => {
    setPopupIsOpen(!popupisOpen)
  }

  const handleDelete = () => {
    console.log('here12')
    const id = currentId

    let ids = input?.addons
  .filter(obj => obj._id !== id) // Filter out the unwanted ID
  .map(obj => obj._id); // Create a new array of IDs

  let formData= new FormData();
  formData.append('addons', JSON.stringify(ids))

    updatePackage(input?._id, formData).then((res) => {
      if (res?.data?.success) {
        alert("Deleted successfully")

        window.location.reload()
      }
      if (!res?.data?.success) {
        alert(res?.data?.message)
      }
    })
    setPopupIsOpen(false)
  }

  const [currentId, setCurrentId] = useState(null)
  const [popupisOpen, setPopupIsOpen] = useState(false)

  const openPopup = (id) => {
    console.log('here.........')
    setCurrentId(id)
    setPopupIsOpen(true)
  }

  const [input, setInput] = useState(init)

  useEffect(() => {
    getPackageData()
    getListingData()
    console.log('input has been set')
  }, [])

  const getListingData = () => {
    getAllListingApi().then((res) => {
      let data = res.data.data
      setOfferings(data.map((e) => e._id))
      console.log(offerings)
    })
  }

  //getAddonData

  const getPackageData = () => {
    getPackageDataApi(id).then((res) => {
      console.log(res.data?.data)
      setindividualPackageData(res.data?.data)
      setInput(res.data?.data)
      console.log('user', individualPackageData)
    })
  }

  const handleCancel = () => {
    navigate('/admin/packages')
  }

  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target
  //   const list = [...addOns]
  //   list[index][name] = value
  //   setAddOns(list)
  // }

  // const handleFileChange = (e, index) => {
  //   const { name, files } = e.target
  //   const list = [...addOns]
  //   list[index][name] = files[0]
  //   setAddOns(list)
  // }

  const handleChange = (e) => {
    console.log(e.target.name, 'target')
    const { name, value } = e.target
    if (name == 'image') {
      setImage(e.target.files[0])
      return
    }
    if (name == 'icon') {
      setIcon(e.target.files[0])
      return
    }
    console.log(name, value)
    setInput({ ...input, [name]: value })
    console.log(input)
  }

  const handleAddonChange = (e) => {
    console.log(e.target.name, 'name')
    console.log(e.target.value, 'value')

    const { name, value } = e.target
    if (name == 'logo') {
      setlogoAddon(e.target.files[0])
      return
    }
    console.log(input)
    const updatedData = {
      ...input,
      addons: [
        ...input.addons.slice(0,editing), // Copy the elements before the updated element
        { ...input.addons[editing], [name]: value }, // Updated element
        ...input.addons.slice(editing + 1), // Copy the elements after the updated element
      ],
    }
    console.log(updatedData)
    setInput(updatedData)
  }

  const handleEditAddon = (id, i) => {
    setEditing(i)
    setCurrentId(id)
    console.log(currentId)
  }


  const handleSaveAddon = (addon_id,i) => {
    const {
      title,
      price
    } = input.addons[i]

    const formData = new FormData()
    formData.append('title', title)
    formData.append('price', price)

    if (logoAddon) formData.append('logo', logoAddon, logoAddon.name)

    updateAddon(addon_id, formData)
      .then((res) => {
        console.log(res.data)
        if (res?.data?.success) {
          window.location.reload(false)
          alert('Addon Updated successfully')
        }
        console.log(res)
        if (!res?.data.success) {
          console.log('error', res)
          alert('res?.data?.message')
        }
      })
      .catch((err) => {
        if (!err?.response?.data?.success) {
          alert(err?.response?.data?.message)
        }
      })
  }

  const handleEdit = () => {
    const {
      name,
      description,
      type,
      // startingPrice,
      categoryType,
      hotSelling,
      endingPrice,
      offerPrice,
      offerings,
      addons,
    } = input

    const formData = new FormData()
    formData.append('name', name)
    formData.append('description', description)
    formData.append('categoryType', categoryType)
    formData.append('type', type)
    formData.append('hotSelling', hotSelling)

    formData.append('offerPrice', offerPrice)
    formData.append('endingPrice', endingPrice)
    formData.append('offerings', JSON.stringify(offerings))
    formData.append('addons', JSON.stringify(addons))


    if (image) formData.append('image', image, image.name)
    if (icon) formData.append('icon', icon, icon.name)

    addOns.forEach((addon, index) => {
      const addonIndex = index + 1
      formData.append(`addon${addonIndex}title`, addon.title)
      formData.append(`addon${addonIndex}price`, addon.price)
      if (addon.logo)
        formData.append(`addon${addonIndex}logo`, addon.logo, addon.logo.name)
    })

    updatePackage(input?._id, formData)
      .then((res) => {
        console.log(res.data)
        if (res?.data?.success) {
          window.location.reload(false)
          alert('Package Updated successfully')
        }
        console.log(res)
        if (!res?.data.success) {
          console.log('error', res)
          alert(res?.data?.message)
        }
      })
      .catch((err) => {
        if (!err?.response?.data?.success) {
          alert(err?.response?.data?.message)
        }
      })
  }

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            // display="flex"
            alignItems="center"
            justifyContent="center"
            // border="1px solid red"
            sx={{ padding: '5em' }}
          >
            <ColorModeContext.Provider value={colorMode}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Box
                      gridColumn="span 8"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                    >
                      <Box
                        mt="25px"
                        p="0 30px"
                        display="flex "
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                            textAlign="center"
                          >
                            Package Details
                          </Typography>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            textAlign="center"
                            color={colors.greenAccent[500]}
                          ></Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  {popupisOpen && (
              <DeletePopup
                message="Are you sure you want to delete this?"
                confirm={handleDelete}
                cancel={togglePopup}
              />
            )}
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      label="Name"
                      name="name"
                      value={input?.name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="description"
                      value={input?.description}
                      onChange={handleChange}
                      label="Description"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Type
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.type}
                          label="Type"
                          name="type"
                          onChange={handleChange}
                        >
                          <MenuItem value={'coloured'}>Coloured</MenuItem>
                          <MenuItem value={'non-coloured'}>
                            Non Coloured
                          </MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Category Type
                        </InputLabel>

                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.categoryType}
                          label="categoryType"
                          name="categoryType"
                          onChange={handleChange}
                        >
                          <MenuItem value={'Press Release'}>
                            Press Release
                          </MenuItem>
                          <MenuItem value={'Sponsored'}>Sponsored</MenuItem>
                          <MenuItem value={'Organic'}>Organic</MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>

                  {/* <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="startingPrice"
                                type="number"
                                value={input?.startingPrice}
                                onChange={handleChange}
                                label="Starting Price" />
                        </Grid> */}
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="endingPrice"
                      type="number"
                      value={input?.endingPrice}
                      onChange={handleChange}
                      label="Original Price"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="offerPrice"
                      type="number"
                      value={input?.offerPrice}
                      onChange={handleChange}
                      label="Offer Price"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          HotSelling
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.hotSelling}
                          label="hotSelling"
                          name="hotSelling"
                          onChange={handleChange}
                        >
                          <MenuItem value={'true'}>Yes</MenuItem>
                          <MenuItem value={'false'}>No</MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Button
                      sx={{
                        backgroundColor: colors.redAccent[700],
                        color: colors.grey[100],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      variant="contained"
                    >
                      <Typography fontWeight="600" marginRight="5px">
                        Upload Image
                      </Typography>

                      <input
                        // hidden
                        accept="image/*"
                        type="file"
                        onChange={handleChange}
                        name="image"
                      />
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Button
                      sx={{
                        backgroundColor: colors.redAccent[700],
                        color: colors.grey[100],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      variant="contained"
                    >
                      <Typography fontWeight="600" marginRight="5px">
                        Upload Logo
                      </Typography>

                      <input
                        // hidden
                        accept="image/*"
                        type="file"
                        onChange={handleChange}
                        name="icon"
                      />
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box>
                      <Autocomplete
                        multiple
                        options={offerings}
                        getOptionLabel={(offering) => offering}
                        value={input?.offerings}
                        onChange={handleOfferingsChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Offerings"
                            variant="outlined"
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ marginTop: '20px' }}>
                    <Link
                      sx={{
                        backgroundColor: 'inherit',
                        marginTop: '2em',
                        border: 'solid 0.5px white',
                        color: 'inherit',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      underline="always"
                      target="_blank"
                      rel="noopener"
                      href={input?.image}
                    >
                      {input?.image
                        ? 'View Uploaded Image'
                        : 'No Image Uploaded'}
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ marginTop: '20px' }}>
                    <Link
                      sx={{
                        backgroundColor: 'inherit',
                        marginTop: '2em',
                        border: 'solid 0.5px white',
                        color: 'inherit',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      underline="always"
                      target="_blank"
                      rel="noopener"
                      href={input?.icon}
                    >
                      {input?.icon ? 'View Uploaded Icon' : 'No Document Icon'}
                    </Link>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Box
                    gridColumn="span 8"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                  >
                    <Box
                      mt="25px"
                      p="0 30px"
                      display="flex "
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box>
                        <Typography
                          variant="h5"
                          fontWeight="600"
                          color={colors.grey[100]}
                          textAlign="center"
                        >
                          Add Ons
                        </Typography>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          textAlign="center"
                          color={colors.greenAccent[500]}
                        ></Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <br></br>

                {input.addons.map((addOn, i) => (
                  <Grid container spacing={2} key={i} sx={{ marginBottom: '30px' }}>
                    <Grid item xs={12} md={4}>
                      <CustomInputField
                        fullWidth
                        label="Title"
                        name="title"
                        value={addOn.title}
                        onChange={handleAddonChange}
                        disabled={editing !== i}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CustomInputField
                        fullWidth
                        label="Price"
                        name="price"
                        value={addOn.price}
                        onChange={handleAddonChange}
                        disabled={editing !== i}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <span style={{ display: 'flex' }}>
                        <Tooltip title="Edit">
                          <div onClick={() => handleEditAddon(addOn._id, i)}>
                            <EditIcon />
                          </div>
                        </Tooltip>
                        <Tooltip title="delete">
                          <div onClick={() => openPopup(addOn._id)}>
                            <DeleteIcon />
                          </div>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;
                          <Tooltip title="Save">
                            <div onClick={() => handleSaveAddon(addOn._id,i)}>
                              <SaveIcon />
                            
                            </div>
                          </Tooltip>
                        
                      </span>

                      <input
                        accept="image/*"
                        type="file"
                        name="logo"
                        onChange={handleAddonChange}
                        disabled={editing !== i}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ marginTop: '20px' }}>
                    <Link
                      sx={{
                        backgroundColor: 'inherit',
                        marginTop: '2em',
                        border: 'solid 0.5px white',
                        color: 'inherit',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      underline="always"
                      target="_blank"
                      rel="noopener"
                      href={addOn?.logo}
                    >
                      {addOn?.logo
                        ? 'View Uploaded Image'
                        : 'No Image Uploaded'}
                    </Link>
                  </Grid>
                  </Grid>
                  
                ))}
                <br></br>
                <Grid item xs={12} md={12}>
                  <Box
                    gridColumn="span 8"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                  >
                    <Box
                      mt="25px"
                      p="0 30px"
                      display="flex "
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box>
                        <Typography
                          variant="h5"
                          fontWeight="600"
                          color={colors.grey[100]}
                          textAlign="center"
                        >
                          Create Add Ons
                        </Typography>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          textAlign="center"
                          color={colors.greenAccent[500]}
                        ></Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <br></br>



                {addOns.map((addOn, i) => (
                    <Grid container spacing={2} key={i}>
                      <Grid item xs={12} md={4}>
                        <CustomInputField
                          fullWidth
                          label="Title"
                          name="title"
                          value={addOn.title}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomInputField
                          fullWidth
                          label="Price"
                          name="price"
                          value={addOn.price}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Button
                          sx={{
                            backgroundColor: colors.redAccent[700],
                            color: colors.grey[100],
                            fontSize: '14px',
                            fontWeight: 'bold',
                            padding: '10px 20px',
                          }}
                          variant="contained"
                        >
                          <Typography fontWeight="600" marginRight="5px">
                            Upload Logo
                          </Typography>
                          <input
                            // hidden
                            accept="image/*"
                            type="file"
                            onChange={(e) => handleFileChange(e, i)}
                            name="logo"
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
                  <Button variant="contained" onClick={handleAddAddOn}>
                    Add More +
                  </Button>

                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        marginTop: '2em',
                        color: colors.grey[100],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      onClick={handleEdit}
                      variant="contained"
                    >
                      Update
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        marginTop: '2em',
                        color: colors.grey[100],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      onClick={handleCancel}
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </ThemeProvider>
            </ColorModeContext.Provider>
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  )
}

export default PackageEditForm

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Link,
} from '@mui/material'
import { tokens, ColorModeContext, useMode } from '../../../theme'
import { CssBaseline, ThemeProvider } from '@mui/material'

import React from 'react'
import {
  CustomInputField,
  CustomSelectBox,
} from '../../../components/CustomInputField'
import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  updateUser,
  getPublisherDataApi,
  updatePublisher,
  getRegionsDataApi,
  getUserPublishers,
} from '../../../service/api'
import { useParams } from 'react-router-dom'

import Autocomplete from '@mui/material/Autocomplete'

function PublisherAddEditForm() {
  const [regions, setRegions] = useState([])

  const handleRegionChange = (_, value) => {
    setPublisherInput({ ...publisherInput, regions: value })
  }

  const [theme, colorMode] = useMode()
  const colors = tokens(theme.palette.mode)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [individualPublisherData, setIndividualPublisherData] = useState()

  const init = {
    firstName: '',
    lastName: '',
    countryOfResidency: '',
    address: '',
    representCompany: false,
    companyName: '',
    country: '',
    companyId: '',
    bankTransfer: false,
    accountNo: '',
    swiftCode: '',
    bankName: '',
    cryptoCurrency: false,
    tokenType: '',
    walletAddress: '',
    fullName: '',
    email: '',
    password: '',
    emailVerified: false,
    userType: 'PUBLISHER',
    userVerified: false,
    showPublisher: false,
    pendingBalance: 0,
    currentBalance: 0,
    telegram: '',
    image: null,
  }

  const initPublisher = {
    websiteLink: '',
    description: '',
    visitors: '',
    companyName: '',
    // offeringCategory: "",
    websiteLanguage: '',
    noFollowLinkAllowed: false,
    doFollowLinkAllowed: false,
    linkedin: '',
    googleNews: '',
    socialShare: '',
    facebook: '',
    twitter: '',
    regions: [],
  }

  const [input, setInput] = useState(init)
  const [publisherInput, setPublisherInput] = useState(initPublisher)
  const [userPublishers, setUserPublishers] = useState([])
  const [image, setImage] = useState(null)

  // const individualPublisherData=useSelector((state)=>state?.publisher?.individualPublisherData)
  // const {isEdit,isNew}=useSelector((state)=>state?.publisher)

  const getAllPublishers = () => {
    getUserPublishers().then((res) => {
      console.log('user publishers...........', res.data?.data)
      setUserPublishers(res.data?.data)
    })
  }

  useEffect(() => {
    getPublisherData()
    getRegionsData()
    getAllPublishers()
  }, [])

  const getRegionsData = () => {
    getRegionsDataApi().then((res) => {
      console.log(res.data?.data)
      setRegions(res.data?.data)
    })
  }

  const getPublisherData = () => {
    getPublisherDataApi(id).then((res) => {
      // res.data?.data.reverse();
      console.log(res.data?.data)
      console.log(res.data?.data.user)
      setIndividualPublisherData(res.data?.data)
      setPublisherInput(res.data?.data)
      setInput(res.data?.data.user)
      console.log('user', individualPublisherData)
    })
  }

  const handleCancel = () => {
    navigate('/admin/publishers')
  }

  const handleChange = (e) => {
    console.log(e.target.name, 'target')
    const { name, value } = e.target

    console.log(name, value)

    setInput({ ...input, [name]: value })
    console.log(input)
  }

  const handlePublisherChange = (e) => {
    console.log(e.target.name, 'target')
    const { name, value } = e.target
    if (name == 'file') {
      setImage(e.target.files[0])
      return
    }
    console.log(name, value)
    setPublisherInput({ ...publisherInput, [name]: value })
    console.log(publisherInput)
  }

  const handleEdit = () => {
    let {
      address,
      bankTransger,
      companyId,
      companyName,
      email,
      firstName,
      country,
      countryOfResidency,
      cryptoCurrency,
      emailVerified,
      fullName,
      lastName,
      representCompany,
      tokenType,
      userType,
      walletAddress,
      bankTransfer,
      bankName,
      swiftCode,
      accountNo,
      userVerified,
      showPublisher,
      currentBalance,
      password,
      pendingBalance,
      telegram,
    } = input
    if (email == individualPublisherData.user.email) {
      updateUser(input?._id, {
        address,
        bankTransger,
        companyId,
        companyName,
        firstName,
        country,
        countryOfResidency,
        cryptoCurrency,
        emailVerified,
        password,
        fullName,
        lastName,
        representCompany,
        tokenType,
        userType,
        walletAddress,
        bankTransfer,
        accountNo,
        swiftCode,
        bankName,
        userVerified,
        showPublisher,
        currentBalance,
        pendingBalance,
        telegram,
      })
        .then((res) => {
          if (res?.data?.success) {
            // alert("Record Updated successfully");
            // window.location.reload(false);
          }
          console.log(res)
          if (!res?.data.success) {
            // console.log("error", res);
            // alert("res?.data?.message");
          }
        })
        .catch((err) => {
          if (!err?.response?.data?.success) {
            alert(err?.response?.data?.message)
          }
        })
    } else {
      updateUser(input?._id, {
        address,
        bankTransger,
        companyId,
        companyName,
        email,
        firstName,
        country,
        countryOfResidency,
        cryptoCurrency,
        emailVerified,
        password,
        fullName,
        lastName,
        representCompany,
        tokenType,
        userType,
        walletAddress,
        bankTransfer,
        accountNo,
        swiftCode,
        bankName,
        userVerified,
        showPublisher,
        currentBalance,
        pendingBalance,
        telegram,
      })
        .then((res) => {
          if (res?.data?.success) {
            // alert("Record Updated successfully");
            // window.location.reload(false);
          }
          console.log(res)
          if (!res?.data.success) {
            // console.log("error", res);
            // alert("res?.data?.message");
          }
        })
        .catch((err) => {
          if (!err?.response?.data?.success) {
            alert(err?.response?.data?.message)
          }
        })
    }
  }

  const handlePublisherEdit = () => {
    const {
      websiteLink,
      description,
      websiteLanguage,
      visitors,
      companyName,
      noFollowLinkAllowed,
      doFollowLinkAllowed,
      linkedin,
      // offeringCategory,
      googleNews,
      socialShare,
      facebook,
      twitter,
      regions,
    } = publisherInput

    console.log(regions)
    const formData = new FormData()
    formData.append('websiteLink', websiteLink)
    formData.append('description', description)
    formData.append('websiteLanguage', websiteLanguage)
    formData.append('companyName', companyName)
    formData.append('linkedin', linkedin)
    formData.append('googleNews', googleNews)
    formData.append('visitors', visitors)
    formData.append('facebook', facebook)
    formData.append('twitter', twitter)
    formData.append('regions', JSON.stringify(regions))
    if (image) formData.append('image', image, image.name)
    if (image) {
      console.log('999999999999')
    }

    updatePublisher(publisherInput?._id, formData)
      .then((res) => {
        if (res?.data?.success) {
          alert('Record Updated successfully')
          window.location.reload(false)
        }
        console.log(res)
        if (!res?.data.success) {
          console.log('error', res)
          alert(res?.data?.message)
        }
      })
      .catch((err) => {
        if (!err?.response?.data?.success) {
          alert(err?.response?.data?.message)
        }
      })
  }

  const handleCombineEdit = async () => {
    await handleEdit()
    await handlePublisherEdit()
  }

  const handleSearchChange = (event) => {
    const query = event.target.value
    setSearchQuery(query)
  }

  const [selectedValue, setSelectedValue] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  const filteredData = userPublishers.filter((item) =>
    item.email.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handleSelect = (event, selectedOption) => {
    if (selectedOption) {
      setInput({ ...input, email: selectedOption })
    }
  }

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            // display="flex"
            alignItems="center"
            justifyContent="center"
            // border="1px solid red"
            sx={{ padding: '5em' }}
          >
            <ColorModeContext.Provider value={colorMode}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Box
                      gridColumn="span 8"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                    >
                      <Box
                        mt="25px"
                        p="0 30px"
                        display="flex "
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                            textAlign="center"
                          >
                            Publishers Details
                          </Typography>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            textAlign="center"
                            color={colors.greenAccent[500]}
                          ></Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="fullName"
                      value={input?.fullName}
                      onChange={handleChange}
                      label="Full Name"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      label="First Name"
                      name="firstName"
                      value={input?.firstName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="lastName"
                      value={input?.lastName}
                      onChange={handleChange}
                      label="Last Name"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="countryOfResidency"
                      value={input?.countryOfResidency}
                      onChange={handleChange}
                      label="Country Of Residency"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="address"
                      value={input?.address}
                      onChange={handleChange}
                      label="Address"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="companyName"
                      value={input?.companyName}
                      onChange={handleChange}
                      label="Company Name"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="country"
                      value={input?.country}
                      onChange={handleChange}
                      label="country"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="companyId"
                      value={input?.companyId}
                      onChange={handleChange}
                      label="Company Id"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="walletAddress"
                      value={input?.walletAddress}
                      onChange={handleChange}
                      label="Wallet Address"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      options={userPublishers.map((e) => e.email)}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField {...params} label="Select an email" />
                      )}
                      onChange={handleSelect}
                      value={input.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      // disabled
                      fullWidth
                      name="password"
                      value={input?.password}
                      onChange={handleChange}
                      label="Password"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="telegram"
                      value={input?.telegram}
                      onChange={handleChange}
                      label="Telegram"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Represent Company
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.representCompany}
                          label="Represent Company"
                          onChange={handleChange}
                          name="representCompany"
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Bank Transfer
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.bankTransfer}
                          label="Bank Transfer"
                          onChange={handleChange}
                          name="bankTransfer"
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <CustomInputField
                        fullWidth
                        name="accountNo"
                        type="string"
                        value={input?.accountNo}
                        onChange={handleChange}
                        label="Bank Account Number"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <CustomInputField
                        fullWidth
                        name="swiftCode"
                        type="string"
                        value={input?.swiftCode}
                        onChange={handleChange}
                        label="Bank Swift Code"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <CustomInputField
                        fullWidth
                        name="bankName"
                        type="string"
                        value={input?.bankName}
                        onChange={handleChange}
                        label="Bank Name"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          cryptoCurrency
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.cryptoCurrency}
                          label="cryptoCurrency"
                          name="cryptoCurrency"
                          onChange={handleChange}
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Email Verified
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.emailVerified}
                          label="Email Verified"
                          name="emailVerified"
                          onChange={handleChange}
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          User Verified
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.userVerified}
                          label="User Verified"
                          name="userVerified"
                          onChange={handleChange}
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No </MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          show Publisher
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.showPublisher}
                          label="show Publisher"
                          name="showPublisher"
                          onChange={handleChange}
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No </MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          User Type
                        </InputLabel>
                        <CustomSelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={input?.userType}
                          label="User Type"
                          name="userType"
                          onChange={handleChange}
                        >
                          <MenuItem value={'PUBLISHER'}>PUBLISHER</MenuItem>
                          <MenuItem value={'ADVERTISER'}>ADVERTISER </MenuItem>
                        </CustomSelectBox>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="currentBalance"
                      type="number"
                      value={input?.currentBalance}
                      onChange={handleChange}
                      label="Current Balance"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="pendingBalance"
                      type="number"
                      value={input?.pendingBalance}
                      onChange={handleChange}
                      label="Pending Balance"
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ marginTop: '20px' }}>
                    <Link
                      sx={{
                        backgroundColor: 'inherit',
                        marginTop: '2em',
                        border: 'solid 0.5px white',
                        color: 'inherit',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      underline="always"
                      target="_blank"
                      rel="noopener"
                      href={input?.image}
                    >
                      {input?.image
                        ? 'View Uploaded Document'
                        : 'No Document Uploaded'}
                    </Link>
                  </Grid>
                </Grid>
                {/* <Grid container spacing={1}>
                    <Grid item >
                        <Button sx={{
                            backgroundColor: colors.blueAccent[700], marginTop: "2em",
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                        onClick={handleEdit}
                            variant="contained">
                            Update
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button sx={{
                            backgroundColor: colors.blueAccent[700], marginTop: "2em",
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                        onClick={handleCancel}
                            variant="contained">
                            Cancel
                        </Button>
                    </Grid>
                    </Grid> */}
                <br></br>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Button
                      sx={{
                        backgroundColor: colors.redAccent[700],
                        color: colors.grey[100],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      variant="contained"
                    >
                      <Typography fontWeight="600" marginRight="5px">
                        Upload Logo
                      </Typography>

                      <input
                        // hidden
                        accept="image/*"
                        type="file"
                        onChange={handlePublisherChange}
                        name="file"
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="companyName"
                      value={publisherInput?.companyName}
                      onChange={handlePublisherChange}
                      label="MarketPlace Company Name"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="websiteLink"
                      // type="number"
                      value={publisherInput?.websiteLink}
                      onChange={handlePublisherChange}
                      label="websiteLink"
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <CustomInputField
                      fullWidth
                      name="description"
                      // type="number"
                      value={publisherInput?.description}
                      onChange={handlePublisherChange}
                      label="description"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="visitors"
                      // type="number"
                      value={publisherInput?.visitors}
                      onChange={handlePublisherChange}
                      label="visitors"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="websiteLanguage"
                      // type="number"
                      value={publisherInput?.websiteLanguage}
                      onChange={handlePublisherChange}
                      label="websiteLanguage"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="linkedin"
                      // type="number"
                      value={publisherInput?.linkedin}
                      onChange={handlePublisherChange}
                      label="linkedin"
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="googleNews"
                                // type="number"
                                value={publisherInput?.googleNews}
                                onChange={handlePublisherChange}
                                label="googleNews" />
                        </Grid> */}
                  {/* <Grid item xs={12} md={4}>
                            <CustomInputField
                                fullWidth
                                name="socialShare"
                                // type="number"
                                value={publisherInput?.socialShare}
                                onChange={handlePublisherChange}
                                label="socialShare" />
                        </Grid> */}
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="facebook"
                      // type="number"
                      value={publisherInput?.facebook}
                      onChange={handlePublisherChange}
                      label="facebook"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      fullWidth
                      name="twitter"
                      // type="number"
                      value={publisherInput?.twitter}
                      onChange={handlePublisherChange}
                      label="twitter"
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ marginTop: '20px' }}>
                    <Link
                      sx={{
                        backgroundColor: 'inherit',
                        marginTop: '2em',
                        border: 'solid 0.5px white',
                        color: 'inherit',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      underline="always"
                      target="_blank"
                      rel="noopener"
                      href={publisherInput?.image}
                    >
                      {publisherInput?.image
                        ? 'View Uploaded Document'
                        : 'No Document Uploaded'}
                    </Link>
                  </Grid>
                  {/* <Grid item xs={12} md={4}>
                        <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">noFollowLinkAllowed</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={publisherInput?.noFollowLinkAllowed}
                                        label="noFollowLinkAllowed"
                                        name="noFollowLinkAllowed"
                                        onChange={handlePublisherChange}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No </MenuItem>
                                    </CustomSelectBox>
                                </FormControl>
                            </Box>
                        </Grid> */}

                  {/* <Grid item xs={12} md={4}>
                        <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">doFollowLinkAllowed</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={publisherInput?.doFollowLinkAllowed}
                                        label="doFollowLinkAllowed"
                                        name="doFollowLinkAllowed"
                                        onChange={handlePublisherChange}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No </MenuItem>
                                    </CustomSelectBox>
                                </FormControl>
                            </Box>
                        </Grid> */}
                  <Grid item xs={12} md={4}>
                    <Box>
                      <Autocomplete
                        multiple
                        options={regions}
                        getOptionLabel={(region) => region.name}
                        value={publisherInput?.regions}
                        onChange={handleRegionChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Regions"
                            variant="outlined"
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} md={4}>

                        <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Offering Category</InputLabel>
                                    <CustomSelectBox
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={publisherInput?.offeringCategory}
                                        label="Offering Category"
                                        onChange={handlePublisherChange}
                                        name="offeringCategory"
                                    >
                                        <MenuItem value={"contentOffering"}>Content Offering</MenuItem>
                                        <MenuItem value={"adsOffering"}>Advertisment Offering</MenuItem>
                                        <MenuItem value={"both"}>Both</MenuItem>

                                    </CustomSelectBox>
                                </FormControl>
                            </Box>
                        </Grid> */}
                </Grid>
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        marginTop: '2em',
                        color: colors.grey[100],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      onClick={handleCombineEdit}
                      variant="contained"
                    >
                      Update
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        marginTop: '2em',
                        color: colors.grey[100],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      onClick={handleCancel}
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </ThemeProvider>
            </ColorModeContext.Provider>
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  )
}

export default PublisherAddEditForm
